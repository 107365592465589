/*********************************************************************
 * Project
 *
 */

/* Grid */

.tpl-project {
  @include span(12 of 12);

  .description {
    @include span(12 of 12);

    @include susy-breakpoint(480px, 12) {
      @include span(11 of 12);
    }

    @include susy-breakpoint(767px, 12) {
      @include span(9 of 12);
    }

    @include susy-breakpoint(1023px, 12) {
      @include span(7 of 12);
    }
  }

  .credits {
    tr {
      // border-top: 1px solid $white-08;
    }

    .daten {
      vertical-align: bottom;
    }

    .label {
      @include susy-breakpoint(639px, 12) {
        white-space: nowrap;
      }
    }
  }
}




/* Project General */


.tpl-project {
  background: $bg-black;
  color: $white;
  font-weight: 300; // 200(thin), 300(light), 400(normal), 700(bold)
  line-height: 2;

  h1 {
    .client,
    .divider {
      font-size: 18px;
      font-weight: 700; // 200(thin), 300(light), 400(normal), 700(bold)
      letter-spacing: 2.5px;
      text-transform: uppercase;
    }

    .colon {
      display: none; // Colon is just present to have a semantic separation between client and title.
    }

    .divider {
      border-right: 1px solid $white;
      font-size: 16px;
      line-height: 1;
      margin-right: 10px;
      padding-right: 9px;
    }

    .title {
      font-size: 17.5px;
      font-weight: 300; // 200(thin), 300(light), 400(normal), 700(bold)
      letter-spacing: 2.5px;
      text-transform: uppercase;
    }

    .btn.btn-vsm { // <a>
      margin-left: 24px;
    }
  }

  .video {
    position: relative;

    .sidenav {
      position: absolute;
      top: calc(50% - 25px);
      z-index: 5;

      &.prev { left: -40px; }
      &.next { right: -40px; }
      a {
        display: block;

        svg {
          // background-color: $sidenav-bg;
          // border-radius: 50%;
          height: 50px;
          width: 50px;

          #next,
          #prev {
            fill: $bg-white;
          }
        }
      }
    }
  }


  .credits {
    letter-spacing: 1.5px;
    text-transform: uppercase;

    td {
      a {
        color: $white;
      }
    }

    .label {
      font-weight: bold;
      padding-right: 20px;
    }

    .daten {
      //
    }
  }

  .description {
    margin-top: 1.75em;
  }

} // END: .tpl-project


/* Video Player */

.tpl-project.tpl-commercial,
.tpl-project.tpl-fiction {

  .video {
    height: 0;
    // overflow: hidden;
    padding-bottom: 56.25%; // Default for 16:9 ratio
    padding-top: 0;
    position: relative;

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

  }
}

/* Image Slider */

.tpl-project.tpl-fiction {

  .slick-slider.no-js {

    .slick-slide {
      display: none;

      &:first-child {
        display: block;
        float: none;
        margin-bottom: 30px;
      }
    }
  }
}


