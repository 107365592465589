/*********************************************************************
 * Directors
 *
 */



/* Grid */

.tpl-directors {
  .director {

    @include susy-breakpoint(589px, 12) {
      @include span(6 of 12);
      &:nth-child(2n) { @include span(6 of 12 last); }
    }

    @include susy-breakpoint(1023px, 12) {
      @include span(4 of 12);
      &:nth-child(2n) { @include span(4 of 12); }
      &:nth-child(3n) { @include span(4 of 12 last); }
    }
  }
}



/* Projects */


// .content--header .content--header--inner .submenu li.hide-on-load { display: none; width: 0; overflow: hidden;}

.tpl-directors {
  .submenu {
    // moved to _area_content--header.scss
  }

  .director {

    .image {
      margin-bottom: 3.25%;
      position: relative;

      img { // Not all images are linked, so <img> needs to be outside of <a>
        border: 1px solid $light-image-border;
        display: inline-block;
        height: auto;
        vertical-align: bottom;
        width: 100%;
      }

      .image--edit-overlay {
        position: absolute;
        right: 10px;
        top: 0;
      }

      .image--text-overlay {

        p {
          margin: 0;
        }

        .btn {
          display: block;
        }
      }
    }
  }
}


/* Projects: Mobile, Tablet (Text Below Images, No Hover for Touch) */

.tpl-directors {
  .content {
    .director {
      .image {

        .image--text-overlay {
          @include text-under-image();
        }
      }
    }
  }
}  // END: .tpl-projects


/* Projects: Desktop (Text Overlays On Hover) */

.tpl-directors {
  .content {

    @include susy-breakpoint(1023px, 12) {

      .director {

        .image {

          .image--text-overlay {
            @include text-overlay();
          }
        }
      }

    } // END: susy-breakpoint

  }
}  // END: .tpl-projects
