/********************************************************************
 * Area Header: Navigation Mobile Menu
 *
 */

/* Responsive Values */

// @include susy-breakpoint(479px,14) {}

/* Mobile Menu */

.mobile-menu--underlay.active {
  background-color: $mobile-menu-underlay;
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 350ms linear;
  width: 100%;
  z-index: 499;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}

.adminbar .mobile-menu { // if adminbar is present, mobile-menu moves down a bit. this value will be fine-tuned by jquery.
  top: 36px;
}

.mobile-menu {
  @extend %not-selectable;
  background-color: $black;
  bottom: 0;
  display: none;
  opacity: 1;
  padding: 24px;
  position: fixed;
  // right: -300px;
  right: 0;
  top: 0;
  width: 280px;
  z-index: 500;

  .mobile-menu--inner {

    .mobile-menu--content {

      .topnav { // <ul>
        list-style: none;
        margin-bottom: 2.5em;
        margin-top: 0;
        padding: 0;
        text-transform: uppercase;

        li {
          display: block;
          line-height: 2.5;
          list-style: none;
          padding: 0;
        }
      } // END: .topnav

      .topnav.branding {
        display: none;
        // li {
        //   width: 78px;
        //   svg {
        //     width: 100%;
        //     height: auto;
        //     fill: $white;
        //   }
        // }
      } // END: .topnav.branding

      .topnav.languages { // ul
        display: inline-block;
        margin-right: auto;

        li {
          display: inline-block;
          line-height: 1;

          border-right: 1px solid $white;
          margin-right: 1em;
          padding-right: 1em;

          &:last-child {
            border-right: 0 solid $white;
            margin-right: 0;
            padding-right: 0;
          }
        }
      } // END: .topnav.languages

      .topnav.primary { // ul
        margin-bottom: 0;

        li {
          // font-size: 18px;
          // font-weight: 300;
          // line-height: 2;
        }
      } // END: .topnav.primary

      .topnav.close { // div
        cursor: pointer;
        height: 70px;
        position: absolute;
        right: 15px;
        top: 10px;
        width: 70px;

        &:hover {
          #menu-close {
            transform: rotate(90deg);
          }
        }

        svg {
          #bg-menu-close { fill: none; }
          #menu-close {
            fill: $white;
            transform-origin: 35px 35px;
            transition: transform 80ms linear;
          }
        }

      } // END: .topnav.close

      .topnav.facebook { // ul
        float: right;
        margin-right: 10px;
        padding-left: auto;
        text-align: right;

        li {
          height: 28px;
          width: 28px;

          svg {
            fill: $white;
            height: auto;
            transition: fill ease .3s;
            width: 100%;

            &:hover {
              fill: $fb-blue;
            }
          }
        }
      } // END: .topnav.facebook

      .topnav.instagram { // ul
        float: right;
        margin-right: 10px;
        padding-left: auto;
        text-align: right;

        li {
          height: 28px;
          width: 28px;

          svg {
            fill: $white;
            height: auto;
            transition: fill ease .3s;
            width: 100%;

            &:hover {
              fill: $white-80;
            }
          }
        }
      } // END: .topnav.instagram


      // Link Text Styles

      .mobile-menu--item { // <a>
        border-bottom: 1px solid transparent;
        color: $white;
        display: inline-block;
        line-height: 1;
        text-decoration: none;
        transition: border-bottom-color ease .3s;

        &:hover {
          border-bottom: 1px solid $white;
        }

        &.current {
          border-bottom: 1px solid transparent;
          cursor: default;
          font-weight: bold;
          pointer-events: none;
        }
      } // END: .mobile-menu--item
    } // END: .mobile-menu--content
  } // END: .mobile-menu--inner
} // END: .mobile-menu
