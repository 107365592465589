/*********************************************************************
 * Contact
 *
 */

/* Grid */

.tpl-contact {
  .container { // = SUSY CONTAINER
    .main {

      .content {
        .address,
        .disclaimer,
        .map,
        .impressum {
          @include span(12 of 12);
        }
      }
    }
  }
} // END: .tpl-contact

/* Contact */


.tpl-contact {

  .content {

    .address,
    .disclaimer {
      font-size: 16px;
      font-weight: 400;

      strong {
        font-weight: 700;
      }

      p:first-child {
        margin-top: 0;
      }
    }

    .address {
      text-transform: uppercase;
    }

    .disclaimer {
      margin-top: 5em;
      margin-bottom: 2em;
    }


    .map {
      height: 0;
      margin-bottom: 2em;
      margin-top: 2em;
      max-width: 100%;
      overflow: hidden;
      padding-bottom: 100%; // 1:1 Ratio for mobile
      position: relative;

      iframe {
        background-color: $bg-google-map-iframe;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        pointer-events: none;

        // Google Map - Grayscale Filter by Joe Watkins https://codepen.io/joe-watkins/pen/fsjrI
        // maybe also useful: https://coderwall.com/p/ehis_g/google-maps-iframe-in-black-and-white
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+ */
        filter: gray; /* IE6-9 */
        -webkit-filter: grayscale(99%); /* Chrome 19+ & Safari 6+ */
        -webkit-backface-visibility: hidden;  /* Fix for transition flickering */
      }
    }


    @include susy-breakpoint(480px, 12) {

      .map {
        margin-bottom: 3em;
        margin-top: 3em;
        padding-bottom: 50%; // 1:2 Ratio for larger screens
      }

      .impressum {
      }
    }
  }
} /* END: .tpl-contact */
