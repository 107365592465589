/*********************************************************************
 * Area Admin Bar
 *
 */


/* Admin Bar Basics */

.admin-bar {
  background-color: $admin-bar-bg;
  border-bottom: 2px solid $admin-bar-border;
  margin-top: 0;
  text-align: right;

  .admin-bar--inner {
    font-size: 16px;
    padding: .4em 0;

    p {
      font-size: 16px;
      line-height: 1;
      margin: 0 $body-padding;
      white-space: nowrap;

      .btn-vsm,
      .btn-group-vsm > .btn {
        border-radius: .2rem;
        font-size: .65rem;
        padding: .2rem .4rem;
      }
    }
  }
}

