/* nunito-sans-200 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/nunito-sans-v12-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-sans-v12-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-sans-v12-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-sans-v12-latin-200.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-sans-v12-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-sans-v12-latin-200.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-sans-300 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/nunito-sans-v12-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-sans-v12-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-sans-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-sans-v12-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-sans-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-sans-v12-latin-300.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-sans-regular - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/nunito-sans-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-sans-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-sans-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-sans-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-sans-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-sans-v12-latin-regular.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-sans-700 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/nunito-sans-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-sans-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-sans-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-sans-v12-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-sans-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-sans-v12-latin-700.svg#NunitoSans') format('svg'); /* Legacy iOS */
}
