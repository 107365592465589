/*********************************************************************
 * Home
 *
 */


/* Grid */

.tpl-home {
  .container { // = SUSY CONTAINER
    .content {


      .content--header {
        display: none;
      }

      img {
        // @include span(12 of 12);
      }
      // @include susy-breakpoint(767px, 12) {
      //   @include pre (2);
      //   @include span(8 of 12);
      // }
    }
  }


  .main-entrance-links {
    padding-bottom: 2rem;
    padding-top: 2rem;
    text-align: center;

    .midnav.primary { // <ul>
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 0;
      text-transform: uppercase;


      .menu--item { // <li>
        color: $black;
        display: inline-block;
        line-height: 1;
        list-style: none;
        margin: 0;
        padding: .15em 0 0;
        border-right: 1px solid $list-item-border;
        margin-right: .75em;
        padding-right: .75em;

        .menu--link { // <a>
          border-bottom: 1px solid transparent;
          display: inline-block;
          line-height: 1;
          text-decoration: none;
          transition: border-bottom-color ease .3s;

          &:hover {
            border-bottom: 1px solid $black;
          }
        }


        &:last-child {
          border-right: 0;
          margin-right: 0;
          padding-right: 0;
        }

      }
    }

    @include susy-breakpoint($menu-breakpoint, 12) {
      display: none;
    }
  }

} // END: .tpl-home

