/*********************************************************************
 * Team
 *
 */

/* Grid */

.tpl-team {
  .container { // = SUSY CONTAINER

    // @include container(1280px 6 28/81 after fluid);  // 28/81 or 0.345679012
    @include container(1280px 12 .13483146 after fluid);  // 28/81 or 0.345679012

    .content {
      .article {

        // SINGLE COLUMN

        @include span(12 of 12);

        // TWO COLUMNS

        @include susy-breakpoint(479px, 12) {
          @include span(6 of 12);
          &:nth-child(2n+2) { @include span(6 of 12 last); } // Set Last Item
        }

        // THREE COLUMNS

        @include susy-breakpoint(767px, 12) {
          @include span(4 of 12);
          &:nth-child(2n+2) { @include span(4 of 12); } // Reset Last Item
          &:nth-child(3n+3) { @include span(4 of 12 last);} // Set Last Item
        }
      }
    }
  }
}  // END: .tpl-team


/* Team: Mobile/Tablet (Text Below Images, No Hover for Touch) */

.tpl-team {
  .article {
    // margin-bottom: 1rem;

    .text {
      @include text-under-image-team();
      min-height: 4em; // Fixes 3-Line Name Problem
    }

    .image {
      line-height: 0;

      img {
        border: 1px solid $light-image-border;
        height: auto;
        width: 100%;
      }
    }
  } // END: .article
}  // END: .tpl-team


/* Team: Desktop (Text Overlays On Hover) */

.tpl-team {
  @include susy-breakpoint(1023px, 12) {
    .article {
      // margin-bottom: 4rem;
      margin-bottom: 1%;
      position: relative;

      .text {
        @include text-overlay();
      }
    }
  } // END: susy-breakpoint

}  // END: .tpl-team
