/*********************************************************************
 * Bootstrap Elements
 *
 */


/* Buttons */

.btn {
  border: 1px solid transparent;
  border-radius: .25rem;
  display: inline-block;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  padding: .5rem 1rem;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.btn:focus,
.btn:hover {
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(2, 117, 216, .25);
          box-shadow: 0 0 0 2px rgba(2, 117, 216, .25);
}

.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
  opacity: .65;
}

.btn:active,
.btn.active {
  background-image: none;
}

/* Button Outline Danger (Red) */

.btn-outline-edit {
  background-color: transparent;
  background-image: none;
  border-color: $btn-outline-danger-red;
  color: $btn-outline-danger-red;
  text-transform: uppercase;
}

.btn-outline-edit:hover {
  background-color: $btn-outline-danger-red;
  border-color: $btn-outline-danger-red;
  color: $white;
}

.btn-outline-edit:focus,
.btn-outline-edit.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(217, 83, 79, .5);
          box-shadow: 0 0 0 2px rgba(217, 83, 79, .5);
}

.btn-outline-edit.disabled,
.btn-outline-edit:disabled {
  background-color: transparent;
  color: $btn-outline-danger-red;
}

.btn-outline-edit:active,
.btn-outline-edit.active,
.show > .btn-outline-edit.dropdown-toggle {
  background-color: $btn-outline-danger-red;
  border-color: $btn-outline-danger-red;
  color: $white;
}

/* Button Very Small */

.btn-vsm,
.btn-group-vsm > .btn {
  border-radius: .2rem;
  font-size: .75rem;
  padding: .25rem .5rem;
}
