/*********************************************************************
 * Footer
 *
 */

footer {

  padding-bottom: 1.4rem;
  padding-top: 4rem;
  ul {
    margin: 0;
    text-transform: uppercase;
    list-style: none;
    padding: 0;
    li {
      &.right {
        text-transform: none;
      }
    }
  }


  @include susy-breakpoint(580px, 12) {
    padding-top: 4rem;
    padding-bottom: 2rem;
    ul {
      li {
        border-left: 1px solid $list-item-border;
        display: inline;
        margin-left: .75em;
        padding-left: .75em;

        &.right {
          float: right;
          border-left: 0 solid $list-item-border;
          margin-left: 0;
          padding-left: 0;
        }

        &:first-child {
          border-left: 0 solid $list-item-border;
          margin-left: 0;
          padding-left: 0;
        }
      }
    }
  }


}


// Color Settings For Dark Backgrounds

.tpl-project footer {
  a {
    color: white;
  }

  @include susy-breakpoint(480px, 12) {
    ul {
      li {
        border-left: 1px solid $list-item-border-on-dark;
        &.right {
          border-left: 0 solid $list-item-border-on-dark;
        }
        &:first-child {
          border-left: 0 solid $list-item-border-on-dark;
        }
      }
    }
  }

}

// Fixed Position for Home

.tpl-home,
.tpl-entertainment,
.tpl-error,
.tpl-jobs {
  footer {
    background-color: rgba(255, 255, 255, .85);
    bottom: 0;
    padding-top: 1rem;
    position: fixed; // absolute
    width: 100%;
  }
}
