@charset "UTF-8";
/* nunito-sans-200 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/nunito-sans-v12-latin-200.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/nunito-sans-v12-latin-200.eot?#iefix") format("embedded-opentype"), url("../fonts/nunito-sans-v12-latin-200.woff2") format("woff2"), url("../fonts/nunito-sans-v12-latin-200.woff") format("woff"), url("../fonts/nunito-sans-v12-latin-200.ttf") format("truetype"), url("../fonts/nunito-sans-v12-latin-200.svg#NunitoSans") format("svg");
  /* Legacy iOS */ }

/* nunito-sans-300 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/nunito-sans-v12-latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/nunito-sans-v12-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/nunito-sans-v12-latin-300.woff2") format("woff2"), url("../fonts/nunito-sans-v12-latin-300.woff") format("woff"), url("../fonts/nunito-sans-v12-latin-300.ttf") format("truetype"), url("../fonts/nunito-sans-v12-latin-300.svg#NunitoSans") format("svg");
  /* Legacy iOS */ }

/* nunito-sans-regular - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/nunito-sans-v12-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/nunito-sans-v12-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/nunito-sans-v12-latin-regular.woff2") format("woff2"), url("../fonts/nunito-sans-v12-latin-regular.woff") format("woff"), url("../fonts/nunito-sans-v12-latin-regular.ttf") format("truetype"), url("../fonts/nunito-sans-v12-latin-regular.svg#NunitoSans") format("svg");
  /* Legacy iOS */ }

/* nunito-sans-700 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/nunito-sans-v12-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/nunito-sans-v12-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/nunito-sans-v12-latin-700.woff2") format("woff2"), url("../fonts/nunito-sans-v12-latin-700.woff") format("woff"), url("../fonts/nunito-sans-v12-latin-700.ttf") format("truetype"), url("../fonts/nunito-sans-v12-latin-700.svg#NunitoSans") format("svg");
  /* Legacy iOS */ }

.mobile-menu {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/*********************************************************************
 * Susy Grid
 *
 */
/* GLOBAL CONTAINER SETTINGS */
.footer,
.container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto; }
  .footer:after,
  .container:after {
    content: " ";
    display: block;
    clear: both; }

html,
body {
  padding: 0;
  margin: 0; }

.body, .body--inner, footer {
  position: relative; }

.body, footer {
  padding-left: 30px;
  padding-right: 30px; }
  @media (min-width: 875px) {
    .body, footer {
      padding-left: 3.48958%;
      /* 67px @ 1920px (full width) */
      padding-right: 3.48958%; } }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick-edit.eot");
  src: url("../fonts/slick-edit.eot?#iefix") format("embedded-opentype"), url("../fonts/slick-edit.woff") format("woff"), url("../fonts/slick-edit.woff2") format("woff2"), url("../fonts/slick-edit.ttf") format("truetype"), url("../fonts/slick-edit.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 40px;
  width: 40px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: black;
  opacity: .7;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  transition: opacity ease .3s; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: black;
    opacity: 1;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 12px;
    line-height: 1;
    color: #0f0e0f;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: opacity ease .3s; }

.slick-prev {
  left: 0px;
  z-index: 1; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: 0px; }
  .slick-prev:before {
    content: ""; }
    [dir="rtl"] .slick-prev:before {
      content: ""; }

.slick-next {
  right: 0px; }
  [dir="rtl"] .slick-next {
    left: 0px;
    right: auto; }
  .slick-next:before {
    content: ""; }
    [dir="rtl"] .slick-next:before {
      content: ""; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -35px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: white;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: white;
      opacity: 0.75; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.cc-window {
  opacity: 1;
  transition: opacity 1s ease;
  border-top: 1px solid #fff; }
  .cc-window.cc-invisible {
    opacity: 0; }
  .cc-window.cc-static {
    position: static; }
  .cc-window.cc-floating {
    padding: 0 3.48958% 1em;
    width: 100%;
    -ms-flex-direction: column;
    flex-direction: column; }
  .cc-window.cc-banner {
    padding: 1em 3.48958%;
    width: 100%;
    -ms-flex-direction: row;
    flex-direction: row; }
  .cc-window.cc-floating, .cc-window.cc-banner {
    background-color: #000;
    color: #fff; }

.cc-animate .cc-revoke {
  transition: transform 1s ease; }

.cc-animate .cc-revoke.cc-top {
  transform: translateY(-2em); }

.cc-animate .cc-revoke.cc-bottom {
  transform: translateY(2em); }

.cc-animate.cc-revoke.cc-active.cc-bottom,
.cc-animate.cc-revoke.cc-active.cc-top,
.cc-revoke:hover {
  transform: translateY(0); }

.cc-grower {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s; }

.cc-revoke,
.cc-window {
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  text-transform: uppercase;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  z-index: 9999; }

.cc-revoke {
  padding: .5em; }

.cc-btn,
.cc-close,
.cc-link,
.cc-revoke {
  cursor: pointer; }

.cc-link {
  display: inline-block;
  padding: .2em; }
  .cc-link:hover {
    text-decoration: underline; }
  .cc-link:active, .cc-link:visited {
    color: #fff; }

.cc-btn {
  display: block;
  padding: .4em .8em;
  border-width: 1px;
  border-color: #ffffff !important;
  border-style: solid;
  background-color: #000;
  color: #fff;
  text-align: center;
  white-space: nowrap; }
  .cc-btn:link, .cc-btn:visited {
    color: #fff;
    border-style: solid;
    border-width: 1px;
    border-color: #ffffff !important; }
  .cc-btn:active, .cc-btn:focus, .cc-btn:hover {
    background-color: #fff;
    color: #000;
    text-decoration: none; }

.cc-floating .cc-btn,
.cc-banner .cc-btn {
  min-width: 120px;
  max-width: 240px;
  margin-left: auto; }

.cc-floating .cc-btn:last-child,
.cc-banner .cc-btn:last-child {
  min-width: 90px;
  max-width: 120px; }

.cc-highlight .cc-btn:first-child {
  background-color: transparent;
  border-color: transparent; }

.cc-highlight .cc-btn:first-child:focus,
.cc-highlight .cc-btn:first-child:hover {
  background-color: transparent;
  text-decoration: underline; }

.cc-close {
  display: block;
  position: absolute;
  top: .5em;
  right: .5em; }

.cc-revoke.cc-top {
  top: 0;
  left: 3em;
  border-bottom-left-radius: .5em;
  border-bottom-right-radius: .5em; }

.cc-revoke.cc-bottom {
  bottom: 0;
  left: 3em;
  border-top-left-radius: .5em;
  border-top-right-radius: .5em; }

.cc-revoke.cc-left {
  left: 3em;
  right: unset; }

.cc-revoke.cc-right {
  right: 3em;
  left: unset; }

.cc-top {
  top: 1em; }

.cc-left {
  left: 1em; }

.cc-right {
  right: 1em; }

.cc-bottom {
  bottom: 0; }

.cc-floating > .cc-link {
  margin-bottom: 1em; }

.cc-floating .cc-message {
  display: block;
  margin-bottom: 1em;
  margin-right: 1em;
  margin-top: 1em; }

.cc-window.cc-floating .cc-compliance {
  -ms-flex: 1;
  flex: 1; }

.cc-window.cc-banner {
  -ms-flex-align: center;
  align-items: center; }

.cc-banner.cc-top {
  left: 0;
  right: 0;
  top: 0; }

.cc-banner.cc-bottom {
  left: 0;
  right: 0;
  bottom: 0; }

.cc-banner .cc-message {
  -ms-flex: 1;
  flex: 1; }

.cc-compliance {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: justify;
  align-content: space-between; }

.cc-compliance > .cc-btn {
  -ms-flex: 1;
  flex: 1; }

.cc-btn + .cc-btn {
  margin-left: .5em; }

@media print {
  .cc-revoke,
  .cc-window {
    display: none; } }

@media screen and (max-width: 900px) {
  .cc-btn {
    white-space: normal; } }

@media screen and (max-width: 414px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window.cc-top {
    top: 0; }
  .cc-window.cc-bottom {
    bottom: 0; }
  .cc-window.cc-banner, .cc-window.cc-left, .cc-window.cc-right {
    left: 0;
    right: 0; }
  .cc-window.cc-banner {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: unset;
    align-items: unset; }
    .cc-window.cc-banner.cc-compliance {
      -ms-flex: 1;
      flex: 1; } }

.cc-floating.cc-theme-classic {
  padding: 1.2em;
  border-radius: 5px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
  text-align: center;
  display: inline;
  -ms-flex: none;
  flex: none; }

.cc-theme-classic .cc-btn {
  border-radius: 5px; }

.cc-theme-classic .cc-btn:last-child {
  min-width: 140px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
  display: inline-block; }

.cc-theme-edgeless.cc-window {
  padding: 0; }

.cc-floating.cc-theme-edgeless .cc-message {
  margin: 2em 2em 1.5em; }

.cc-banner.cc-theme-edgeless .cc-btn {
  margin: 0;
  padding: .8em 1.8em;
  height: 100%; }

.cc-banner.cc-theme-edgeless .cc-message {
  margin-left: 1em; }

.cc-floating.cc-theme-edgeless .cc-btn + .cc-btn {
  margin-left: 0; }

/*********************************************************************
 * 1. General HTML tags
 *
 */
html {
  font-size: 16px;
  line-height: 1.8; }
  html.noscroll {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0; }

h1 {
  font-size: 38px;
  line-height: 36px; }

h2 {
  font-size: 24px;
  line-height: 1.4; }

html, body {
  height: 100%; }

* {
  box-sizing: border-box; }

div::-moz-focus-inner,
input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0 none transparent; }

a, img, textarea, input, button {
  outline: 0 none transparent; }

html {
  overflow-y: scroll;
  background-color: white;
  color: #0f0e0f;
  margin: 0; }

body {
  margin: 0; }

body, td, input[type=text], textarea {
  font-family: "Nunito Sans", "Avenir", "Avenir Next", serif;
  font-weight: normal;
  font-size: 14px; }

img {
  max-width: 100%; }

h1 {
  font-weight: normal; }

h2 {
  font-weight: normal;
  color: #0f0e0f; }

h3 {
  color: #0f0e0f;
  font-weight: normal;
  text-transform: uppercase;
  margin-top: 0;
  padding: 0;
  line-height: 1; }

p {
  margin: 1em 0;
  padding: 0; }

a:link,
a:visited,
a:hover,
a:active,
a:focus {
  outline: 0; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:active,
a:hover {
  outline-width: 0; }

a {
  color: #0f0e0f;
  text-decoration: none; }

a:focus, a:hover {
  color: #0f0e0f;
  text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

/* Links that are not yet implemented */
a.wip:hover, a.wip:active, a.wip:focus {
  color: red !important;
  text-decoration: line-through !important;
  border: 0; }

blockquote {
  margin-left: 0;
  padding-left: 1.5em;
  padding-right: 2em;
  border-left: 4px solid #ddd;
  font-style: italic;
  color: #777; }

pre, code {
  background: #eee;
  border: 1px solid #ddd; }

b, strong {
  font-weight: 700; }

pre {
  font-size: 14px;
  line-height: 1.4em;
  padding: 1em;
  border-left: 4px solid #ddd; }

/* Table */
table,
thead,
tbody,
tfoot,
tr,
th,
td {
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: none;
  border-collapse: inherit;
  border-spacing: 0;
  border-color: inherit;
  vertical-align: top;
  text-align: left;
  font-weight: inherit;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0; }

/*********************************************************************
 * Back To Top Button
 *
 */
/* Back to top button */
.back2top {
  bottom: 10px;
  display: none;
  left: 0;
  padding-left: 3.48958%;
  padding-right: 3.48958%;
  pointer-events: none;
  position: fixed;
  right: 0;
  text-align: right;
  width: 100%; }
  @media (min-width: 480px) {
    .back2top {
      bottom: 60px; } }
  .back2top .back2top--inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    width: 100%; }
    .back2top .back2top--inner .back2top--button {
      cursor: pointer;
      display: inline-block;
      height: 70px;
      margin-right: 2.1875%;
      pointer-events: auto;
      position: relative;
      vertical-align: bottom;
      width: 70px; }
      .back2top .back2top--inner .back2top--button svg {
        height: 70px;
        width: 70px; }
        .back2top .back2top--inner .back2top--button svg #circle {
          fill: #0f0e0f;
          transition: fill ease .3s; }
        .back2top .back2top--inner .back2top--button svg #arrow {
          fill: white;
          transition: fill ease .3s; }
        .back2top .back2top--inner .back2top--button svg #bg {
          fill: none; }
        .back2top .back2top--inner .back2top--button svg:hover #up {
          fill: #555; }

/*********************************************************************
 * Bootstrap Elements
 *
 */
/* Buttons */
.btn {
  border: 1px solid transparent;
  border-radius: .25rem;
  display: inline-block;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  padding: .5rem 1rem;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }

.btn:focus,
.btn:hover {
  text-decoration: none; }

.btn:focus,
.btn.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.25);
  box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.25); }

.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
  opacity: .65; }

.btn:active,
.btn.active {
  background-image: none; }

/* Button Outline Danger (Red) */
.btn-outline-edit {
  background-color: transparent;
  background-image: none;
  border-color: #d9534f;
  color: #d9534f;
  text-transform: uppercase; }

.btn-outline-edit:hover {
  background-color: #d9534f;
  border-color: #d9534f;
  color: white; }

.btn-outline-edit:focus,
.btn-outline-edit.focus {
  -webkit-box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5);
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5); }

.btn-outline-edit.disabled,
.btn-outline-edit:disabled {
  background-color: transparent;
  color: #d9534f; }

.btn-outline-edit:active,
.btn-outline-edit.active,
.show > .btn-outline-edit.dropdown-toggle {
  background-color: #d9534f;
  border-color: #d9534f;
  color: white; }

/* Button Very Small */
.btn-vsm,
.btn-group-vsm > .btn {
  border-radius: .2rem;
  font-size: .75rem;
  padding: .25rem .5rem; }

/*********************************************************************
 * Hamburger
 * Source: https://codepen.io/anon/pen/qmeVRY
 */
.hamburger {
  display: block; }

@media (min-width: 940px) {
  .hamburger {
    display: none; } }

.hamburger {
  cursor: pointer;
  height: 42px;
  position: absolute;
  right: -10px;
  top: 20px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  width: 50px;
  z-index: 505;
  background-color: transparent;
  border: 0;
  color: inherit;
  font: inherit;
  margin: 0;
  overflow: visible;
  text-transform: none; }
  .hamburger span {
    background: #0f0e0f;
    display: block;
    height: 2px;
    left: 10px;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    width: 30px; }
    .hamburger span:nth-child(1) {
      top: 10px; }
    .hamburger span:nth-child(2), .hamburger span:nth-child(3) {
      top: 20px; }
    .hamburger span:nth-child(4) {
      top: 30px; }
  .hamburger.is-active span {
    background-color: white;
    right: 49px; }
    .hamburger.is-active span:nth-child(1) {
      left: 23px;
      top: 20px;
      width: 0%; }
    .hamburger.is-active span:nth-child(2) {
      transform: rotate(45deg); }
    .hamburger.is-active span:nth-child(3) {
      transform: rotate(-45deg); }
    .hamburger.is-active span:nth-child(4) {
      left: 23px;
      top: 20px;
      width: 0%; }

/*********************************************************************
 * Slider for Büro and Projekt
 *
 */
.slicklist {
  background-color: rgba(0, 0, 0, 0.05); }

.slick-prev, .slick-next {
  color: red; }

/* Adjusting the slick slider dots */
.slick-dots li {
  height: 14px;
  width: 10px; }
  .slick-dots li button:before {
    font-size: 10px; }

.aa-slide {
  height: 100%;
  margin: 0;
  outline: 0 none transparent; }
  .aa-slide img {
    width: auto; }
  .aa-slide .text-box {
    box-sizing: border-box;
    width: 420px;
    height: 100%;
    padding: 0 20px 10px 60px;
    overflow-y: auto; }
    .aa-slide .text-box h1, .aa-slide .text-box h2, .aa-slide .text-box h3, .aa-slide .text-box h4, .aa-slide .text-box h5, .aa-slide .text-box h6 {
      padding-top: 0;
      margin-top: 0;
      font-size: 24px;
      line-height: 1.2;
      font-weight: 300;
      color: black;
      border: 0; }

.slick-prev:before,
.slick-next:before {
  color: white; }

/*********************************************************************
 * Area Admin Bar
 *
 */
/* Admin Bar Basics */
.admin-bar {
  background-color: #f1f1f1;
  border-bottom: 2px solid #e1e1e1;
  margin-top: 0;
  text-align: right; }
  .admin-bar .admin-bar--inner {
    font-size: 16px;
    padding: .4em 0; }
    .admin-bar .admin-bar--inner p {
      font-size: 16px;
      line-height: 1;
      margin: 0 3.48958%;
      white-space: nowrap; }
      .admin-bar .admin-bar--inner p .btn-vsm,
      .admin-bar .admin-bar--inner p .btn-group-vsm > .btn {
        border-radius: .2rem;
        font-size: .65rem;
        padding: .2rem .4rem; }

/********************************************************************
 * Area Header: Navigation Mobile Menu
 *
 */
/* Responsive Values */
/* Mobile Menu */
.mobile-menu--underlay.active {
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 350ms linear;
  width: 100%;
  z-index: 499; }
  .mobile-menu--underlay.active.active {
    opacity: 1;
    pointer-events: auto; }

.adminbar .mobile-menu {
  top: 36px; }

.mobile-menu {
  background-color: #0f0e0f;
  bottom: 0;
  display: none;
  opacity: 1;
  padding: 24px;
  position: fixed;
  right: 0;
  top: 0;
  width: 280px;
  z-index: 500; }
  .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav {
    list-style: none;
    margin-bottom: 2.5em;
    margin-top: 0;
    padding: 0;
    text-transform: uppercase; }
    .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav li {
      display: block;
      line-height: 2.5;
      list-style: none;
      padding: 0; }
  .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav.branding {
    display: none; }
  .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav.languages {
    display: inline-block;
    margin-right: auto; }
    .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav.languages li {
      display: inline-block;
      line-height: 1;
      border-right: 1px solid white;
      margin-right: 1em;
      padding-right: 1em; }
      .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav.languages li:last-child {
        border-right: 0 solid white;
        margin-right: 0;
        padding-right: 0; }
  .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav.primary {
    margin-bottom: 0; }
  .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav.close {
    cursor: pointer;
    height: 70px;
    position: absolute;
    right: 15px;
    top: 10px;
    width: 70px; }
    .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav.close:hover #menu-close {
      transform: rotate(90deg); }
    .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav.close svg #bg-menu-close {
      fill: none; }
    .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav.close svg #menu-close {
      fill: white;
      transform-origin: 35px 35px;
      transition: transform 80ms linear; }
  .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav.facebook {
    float: right;
    margin-right: 10px;
    padding-left: auto;
    text-align: right; }
    .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav.facebook li {
      height: 28px;
      width: 28px; }
      .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav.facebook li svg {
        fill: white;
        height: auto;
        transition: fill ease .3s;
        width: 100%; }
        .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav.facebook li svg:hover {
          fill: #3b5998; }
  .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav.instagram {
    float: right;
    margin-right: 10px;
    padding-left: auto;
    text-align: right; }
    .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav.instagram li {
      height: 28px;
      width: 28px; }
      .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav.instagram li svg {
        fill: white;
        height: auto;
        transition: fill ease .3s;
        width: 100%; }
        .mobile-menu .mobile-menu--inner .mobile-menu--content .topnav.instagram li svg:hover {
          fill: rgba(255, 255, 255, 0.8); }
  .mobile-menu .mobile-menu--inner .mobile-menu--content .mobile-menu--item {
    border-bottom: 1px solid transparent;
    color: white;
    display: inline-block;
    line-height: 1;
    text-decoration: none;
    transition: border-bottom-color ease .3s; }
    .mobile-menu .mobile-menu--inner .mobile-menu--content .mobile-menu--item:hover {
      border-bottom: 1px solid white; }
    .mobile-menu .mobile-menu--inner .mobile-menu--content .mobile-menu--item.current {
      border-bottom: 1px solid transparent;
      cursor: default;
      font-weight: bold;
      pointer-events: none; }

/*********************************************************************
 * Area Header: Navigation Desktop
 *
 */
/* Header Menu Adjustments for Mobile (Main Menu not visible) */
.header-menu {
  height: 90px;
  margin: 0;
  padding-top: 31px; }

.header-menu--content .header-menu--center {
  display: none; }

.header-menu--content .header-menu--left {
  display: flex;
  flex-shrink: 0; }

.tpl-home .header-menu--content .header-menu--center {
  margin-left: 0;
  margin-right: 0;
  display: none; }

.tpl-home .header-menu--content .header-menu--left {
  display: none; }

.header-menu--content {
  display: flex;
  justify-content: space-between; }
  .header-menu--content .header-menu--center {
    display: none;
    flex: 1;
    margin-left: 0;
    margin-right: 0; }
  .header-menu--content .header-menu--right,
  .header-menu--content .topnav.instagram,
  .header-menu--content .topnav.facebook {
    display: none; }
  .header-menu--content .topnav {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    text-transform: uppercase; }
    .header-menu--content .topnav li {
      align-self: center;
      display: inline-block;
      line-height: 1;
      list-style: none;
      margin: 0;
      padding: .15em 0 0; }
      .header-menu--content .topnav li:last-child {
        margin-right: 0; }
  .header-menu--content .topnav.primary {
    margin-left: auto;
    margin-right: auto; }
    .header-menu--content .topnav.primary li {
      border-right: 1px solid #555;
      margin-right: .75em;
      padding-right: .75em; }
    .header-menu--content .topnav.primary li:last-child {
      border-right: 0;
      margin-right: 0;
      padding-right: 0; }
  .header-menu--content .topnav.branding li {
    line-height: 0;
    margin: 0;
    padding: 0;
    width: 78px; }
  .header-menu--content .topnav.languages {
    display: none; }

@media (min-width: 640px) {
  .header-menu--content .header-menu--center {
    margin-right: 93px; }
  .tpl-home .header-menu--content .header-menu--center {
    margin-right: 1em; } }

@media (min-width: 940px) {
  .mobile-menu,
  .mobile-menu--underlay {
    display: none !important; }
  .header-menu {
    height: 117px;
    padding-top: 24px; }
    .header-menu .header-menu--inner .header-menu--content .header-menu--left,
    .header-menu .header-menu--inner .header-menu--content .header-menu--center,
    .header-menu .header-menu--inner .header-menu--content .header-menu--right {
      display: flex; }
    .header-menu .header-menu--inner .header-menu--content .header-menu--center {
      margin-right: 0; }
    .header-menu .header-menu--inner .header-menu--content .topnav.languages {
      margin-left: 2em; }
      .header-menu .header-menu--inner .header-menu--content .topnav.languages li {
        border-right: 1px solid #555;
        margin-right: .75em;
        padding-right: .75em; }
        .header-menu .header-menu--inner .header-menu--content .topnav.languages li:last-child {
          border-right: 0 solid #555;
          margin-right: 0;
          padding-right: 0; }
    .header-menu .header-menu--inner .header-menu--content .topnav.secondary {
      margin-left: auto;
      margin-right: 0; }
      .header-menu .header-menu--inner .header-menu--content .topnav.secondary li {
        border-right: 1px solid #555;
        margin-right: .6em;
        padding-right: .6em; }
        .header-menu .header-menu--inner .header-menu--content .topnav.secondary li:last-child {
          border-right: 0;
          margin-right: 0;
          padding-right: 0; }
    .header-menu .header-menu--inner .header-menu--content .topnav.facebook {
      display: flex;
      margin-left: 1.0em; }
      .header-menu .header-menu--inner .header-menu--content .topnav.facebook li {
        height: 18px;
        width: 18px; }
        .header-menu .header-menu--inner .header-menu--content .topnav.facebook li svg {
          fill: #0f0e0f;
          height: auto;
          transition: fill ease .3s;
          width: 100%; }
          .header-menu .header-menu--inner .header-menu--content .topnav.facebook li svg:hover {
            fill: #3b5998; }
    .header-menu .header-menu--inner .header-menu--content .topnav.instagram {
      display: flex;
      margin-left: 1.0em; }
      .header-menu .header-menu--inner .header-menu--content .topnav.instagram li {
        height: 18px;
        width: 18px; }
        .header-menu .header-menu--inner .header-menu--content .topnav.instagram li svg {
          fill: #0f0e0f;
          height: auto;
          transition: fill ease .3s;
          width: 100%; }
          .header-menu .header-menu--inner .header-menu--content .topnav.instagram li svg:hover {
            fill: rgba(15, 14, 15, 0.65); }
    .header-menu .header-menu--inner .header-menu--content .topnav.languages {
      display: block; } }

/* Basic Header Nav Settings */
.header-menu .header-menu--content .header-menu--left .menu--item,
.header-menu .header-menu--content .header-menu--center .menu--item,
.header-menu .header-menu--content .header-menu--right .menu--item {
  border-bottom: 1px solid transparent;
  color: #0f0e0f;
  display: inline-block;
  line-height: 1;
  text-decoration: none;
  transition: border-bottom-color ease .3s; }
  .header-menu .header-menu--content .header-menu--left .menu--item:hover,
  .header-menu .header-menu--content .header-menu--center .menu--item:hover,
  .header-menu .header-menu--content .header-menu--right .menu--item:hover {
    border-bottom: 1px solid #0f0e0f; }
  .header-menu .header-menu--content .header-menu--left .menu--item.current,
  .header-menu .header-menu--content .header-menu--center .menu--item.current,
  .header-menu .header-menu--content .header-menu--right .menu--item.current {
    border-bottom: 1px solid transparent;
    cursor: default;
    font-weight: bold;
    pointer-events: none; }

/*********************************************************************
 * Area Header: Navigation Project
 *
 */
.tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--admin .btn.admin, .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--admin .spacer {
  display: none; }

@media (min-width: 479px) {
  .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--admin .btn.admin, .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--admin .spacer {
    display: inline-block; } }

.tpl-project .header-menu--project .header-menu--inner .header-menu--content {
  display: flex;
  justify-content: space-between;
  height: 75px;
  width: 100%;
  margin-right: 0; }
  .tpl-project .header-menu--project .header-menu--inner .header-menu--content .topnav {
    display: flex;
    margin: 0;
    text-transform: uppercase;
    list-style: none;
    padding: 0; }
    .tpl-project .header-menu--project .header-menu--inner .header-menu--content .topnav li {
      align-self: center;
      display: inline-block;
      line-height: 1;
      list-style: none;
      margin: 0;
      padding: .15em 0 0; }
      .tpl-project .header-menu--project .header-menu--inner .header-menu--content .topnav li:last-child {
        margin-right: 0; }
      .tpl-project .header-menu--project .header-menu--inner .header-menu--content .topnav li .menu--item {
        border-bottom: 1px solid transparent;
        color: white;
        display: inline-block;
        line-height: 1;
        text-decoration: none;
        transition: border-bottom-color ease .3s; }
        .tpl-project .header-menu--project .header-menu--inner .header-menu--content .topnav li .menu--item:hover {
          border-bottom: 1px solid white; }
        .tpl-project .header-menu--project .header-menu--inner .header-menu--content .topnav li .menu--item.current {
          font-weight: bold;
          cursor: default;
          border-bottom: 1px solid transparent;
          pointer-events: none; }
  .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--left {
    display: flex;
    flex: 1; }
    .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--left .topnav.branding li {
      width: 78px; }
      .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--left .topnav.branding li svg {
        width: 100%;
        height: auto;
        fill: white; }
    .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--left .topnav.languages {
      margin-left: 2em; }
      .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--left .topnav.languages li {
        border-right: 1px solid #ccc;
        margin-right: .75em;
        padding-right: .75em; }
        .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--left .topnav.languages li:last-child {
          border-right: 0 solid #ccc;
          margin-right: 0;
          padding-right: 0; }
  .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--close {
    align-self: center;
    margin-right: 0;
    display: inline-block; }
    .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--close a.close {
      margin-right: -10px;
      display: inline-block;
      text-align: center;
      vertical-align: top;
      width: 50px;
      height: 50px;
      transition: all ease 0.18s; }
      .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--close a.close:hover, .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--close a.close:active, .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--close a.close:focus {
        border: 0; }
      .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--close a.close svg #bg-close {
        fill: #0f0e0f;
        transition: fill ease 0.18s; }
      .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--close a.close svg #x-close {
        fill: white;
        transition: fill ease 0.18s; }
      .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--close a.close svg:hover #bg-close {
        fill: #0f0e0f; }
      .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--close a.close svg:hover #x-close {
        fill: white; }
  .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--admin {
    display: flex;
    flex: 0;
    width: auto;
    align-self: center;
    padding-right: 30px; }
    .tpl-project .header-menu--project .header-menu--inner .header-menu--content .header-menu--admin .topnav.admin {
      margin: 0;
      white-space: nowrap; }

@media (min-width: 767px) {
  .content--header .content--header--inner .submenu {
    margin-left: 8.41667%;
    width: 83.16667%;
    float: left;
    margin-right: 1.0%; } }

.content--header {
  margin-bottom: 2em; }
  .content--header:after {
    clear: both;
    content: " ";
    display: block;
    line-height: 0; }
  .content--header .content--header--inner .submenu {
    font-weight: normal;
    line-height: 1.5;
    list-style: none;
    margin-bottom: 0;
    margin-top: 1em;
    padding: 2% 0 2.5%;
    text-align: center;
    text-transform: uppercase; }
    .content--header .content--header--inner .submenu.directors {
      text-transform: none; }
    .content--header .content--header--inner .submenu li {
      display: inline-block;
      height: rem(15);
      line-height: 1.25;
      list-style: none;
      margin-right: .425em;
      margin-left: .425em;
      vertical-align: middle; }
      @media (min-width: 480px) {
        .content--header .content--header--inner .submenu li.divider-after {
          border-right: 1px solid #555;
          line-height: 1.25;
          padding: 0 .9em 0 0; } }
    .content--header .content--header--inner .submenu .submenu-divider--mobile {
      border: 0;
      border-bottom: 1px solid #555;
      height: 0;
      margin: 1em auto .8em;
      padding: 0;
      width: 20px; }
      @media (min-width: 480px) {
        .content--header .content--header--inner .submenu .submenu-divider--mobile {
          display: none; } }
    .content--header .content--header--inner .submenu .submenu--item {
      border-bottom: 1px solid transparent;
      color: #0f0e0f;
      display: inline-block;
      text-decoration: none;
      transition: border-bottom-color ease .3s; }
      .content--header .content--header--inner .submenu .submenu--item.current {
        font-weight: bold; }
      .content--header .content--header--inner .submenu .submenu--item:hover {
        border-bottom: 1px solid #0f0e0f; }
      .content--header .content--header--inner .submenu .submenu--item.no-link:hover {
        border-bottom: 1px solid transparent; }

.tpl-home .content--header,
.tpl-project .content--header {
  display: none; }

/*********************************************************************
 * Main content and sidebars
 *
 */
.align_left {
  /* for images placed in rich text editor */
  float: left;
  margin: 0 1em 0.5em 0;
  position: relative;
  top: 0.5em;
  max-width: 50%; }

.align_right {
  /* for images placed in rich text editor */
  float: right;
  margin: 0 0 0.5em 1em;
  max-width: 50%; }

.align_center {
  /* for images placed in rich text editor */
  display: block;
  margin: 1em auto;
  position: relative;
  top: .5em; }

figure {
  display: table;
  margin: 1em 0;
  width: 1px; }

figure img {
  display: table-row;
  margin-bottom: .5em; }

figure figcaption {
  color: #777;
  display: table-row;
  font-size: smaller;
  line-height: 1.4em; }

/*********************************************************************
 * Footer
 *
 */
footer {
  padding-bottom: 1.4rem;
  padding-top: 4rem; }
  footer ul {
    margin: 0;
    text-transform: uppercase;
    list-style: none;
    padding: 0; }
    footer ul li.right {
      text-transform: none; }
  @media (min-width: 580px) {
    footer {
      padding-top: 4rem;
      padding-bottom: 2rem; }
      footer ul li {
        border-left: 1px solid #555;
        display: inline;
        margin-left: .75em;
        padding-left: .75em; }
        footer ul li.right {
          float: right;
          border-left: 0 solid #555;
          margin-left: 0;
          padding-left: 0; }
        footer ul li:first-child {
          border-left: 0 solid #555;
          margin-left: 0;
          padding-left: 0; } }

.tpl-project footer a {
  color: white; }

@media (min-width: 480px) {
  .tpl-project footer ul li {
    border-left: 1px solid #ccc; }
    .tpl-project footer ul li.right {
      border-left: 0 solid #ccc; }
    .tpl-project footer ul li:first-child {
      border-left: 0 solid #ccc; } }

.tpl-home footer,
.tpl-entertainment footer,
.tpl-error footer,
.tpl-jobs footer {
  background-color: rgba(255, 255, 255, 0.85);
  bottom: 0;
  padding-top: 1rem;
  position: fixed;
  width: 100%; }

/*********************************************************************
 * About
 *
 */
/* Grid */
.tpl-about .container .main .content .article {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0; }
  .tpl-about .container .main .content .article .text {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    @media (min-width: 767px) {
      .tpl-about .container .main .content .article .text {
        width: 49.5%;
        float: left;
        margin-right: 1.0%; } }
  .tpl-about .container .main .content .article .image {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }

/* About */
.tpl-about .container .main .content .article .image img {
  width: 100%;
  height: auto;
  margin-bottom: 2em; }

/*********************************************************************
 * Contact
 *
 */
/* Grid */
.tpl-contact .container .main .content .address,
.tpl-contact .container .main .content .disclaimer,
.tpl-contact .container .main .content .map,
.tpl-contact .container .main .content .impressum {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

/* Contact */
.tpl-contact .content .address,
.tpl-contact .content .disclaimer {
  font-size: 16px;
  font-weight: 400; }
  .tpl-contact .content .address strong,
  .tpl-contact .content .disclaimer strong {
    font-weight: 700; }
  .tpl-contact .content .address p:first-child,
  .tpl-contact .content .disclaimer p:first-child {
    margin-top: 0; }

.tpl-contact .content .address {
  text-transform: uppercase; }

.tpl-contact .content .disclaimer {
  margin-top: 5em;
  margin-bottom: 2em; }

.tpl-contact .content .map {
  height: 0;
  margin-bottom: 2em;
  margin-top: 2em;
  max-width: 100%;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative; }
  .tpl-contact .content .map iframe {
    background-color: #eaeaea;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    pointer-events: none;
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    /* Firefox 10+ */
    filter: gray;
    /* IE6-9 */
    -webkit-filter: grayscale(99%);
    /* Chrome 19+ & Safari 6+ */
    -webkit-backface-visibility: hidden;
    /* Fix for transition flickering */ }

@media (min-width: 480px) {
  .tpl-contact .content .map {
    margin-bottom: 3em;
    margin-top: 3em;
    padding-bottom: 50%; } }

/* END: .tpl-contact */
/*********************************************************************
 * Directors
 *
 */
/* Grid */
@media (min-width: 589px) {
  .tpl-directors .director {
    width: 49.5%;
    float: left;
    margin-right: 1.0%; }
    .tpl-directors .director:nth-child(2n) {
      width: 49.5%;
      float: right;
      margin-right: 0; } }

@media (min-width: 1023px) {
  .tpl-directors .director {
    width: 32.66667%;
    float: left;
    margin-right: 1.0%; }
    .tpl-directors .director:nth-child(2n) {
      width: 32.66667%;
      float: left;
      margin-right: 1.0%; }
    .tpl-directors .director:nth-child(3n) {
      width: 32.66667%;
      float: right;
      margin-right: 0; } }

/* Projects */
.tpl-directors .director .image {
  margin-bottom: 3.25%;
  position: relative; }
  .tpl-directors .director .image img {
    border: 1px solid #f0f0f0;
    display: inline-block;
    height: auto;
    vertical-align: bottom;
    width: 100%; }
  .tpl-directors .director .image .image--edit-overlay {
    position: absolute;
    right: 10px;
    top: 0; }
  .tpl-directors .director .image .image--text-overlay p {
    margin: 0; }
  .tpl-directors .director .image .image--text-overlay .btn {
    display: block; }

/* Projects: Mobile, Tablet (Text Below Images, No Hover for Touch) */
.tpl-directors .content .director .image .image--text-overlay {
  color: #0f0e0f;
  font-size: 14px;
  letter-spacing: .75px;
  line-height: 1.35;
  margin-top: .35em;
  text-transform: uppercase; }
  .tpl-directors .content .director .image .image--text-overlay .name {
    display: block;
    font-size: 14px;
    font-weight: bold; }
  .tpl-directors .content .director .image .image--text-overlay .divider {
    border-right: 1px solid #0f0e0f;
    font-size: 11px;
    line-height: 1;
    margin-right: 7px;
    padding-right: 7px; }
  .tpl-directors .content .director .image .image--text-overlay .colon {
    display: none; }
  .tpl-directors .content .director .image .image--text-overlay .client,
  .tpl-directors .content .director .image .image--text-overlay .colon {
    font-size: 14px;
    font-weight: bold; }

/* Projects: Desktop (Text Overlays On Hover) */
@media (min-width: 1023px) {
  .tpl-directors .content .director .image .image--text-overlay {
    align-items: center;
    bottom: 0;
    color: transparent;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    justify-content: center;
    left: 0;
    letter-spacing: 1.5px;
    margin-top: 0;
    padding: 24px;
    position: absolute;
    right: 0;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    transition: background ease .6s, color ease .6s; }
    .tpl-directors .content .director .image .image--text-overlay .client {
      border-right: 1px solid rgba(255, 255, 255, 0);
      font-size: 14px;
      font-weight: bold;
      margin-right: 10px;
      padding-right: 10px;
      transition: border-color ease .6s; }
    .tpl-directors .content .director .image .image--text-overlay .name a {
      color: transparent;
      transition: color ease .6s; }
    .tpl-directors .content .director .image .image--text-overlay .colon {
      display: none; }
    .tpl-directors .content .director .image .image--text-overlay .divider {
      display: none; }
    .tpl-directors .content .director .image .image--text-overlay:hover {
      background-color: rgba(15, 14, 15, 0.65);
      border: 0;
      color: white;
      text-decoration: none; }
      .tpl-directors .content .director .image .image--text-overlay:hover .client {
        border-right: 1px solid rgba(255, 255, 255, 0.8); }
      .tpl-directors .content .director .image .image--text-overlay:hover .name a {
        color: white; } }

/*********************************************************************
 * Director
 *
 */
/* Grid Portrait & Bio */
@media (min-width: 589px) {
  .tpl-director .content .article .portrait {
    width: 49.5%;
    float: left;
    margin-right: 1.0%; }
  .tpl-director .content .article .bio {
    width: 49.5%;
    float: right;
    margin-right: 0; } }

@media (min-width: 1023px) {
  .tpl-director .content .article .portrait {
    width: 66.33333%;
    float: left;
    margin-right: 1.0%; }
  .tpl-director .content .article .bio {
    width: 32.66667%;
    float: right;
    margin-right: 0;
    margin-left: 0%; } }

/* Grid Related */
.tpl-director .content .related .related--item.commercial {
  margin-left: 8.41667%;
  width: 83.16667%;
  float: left;
  margin-right: 1.0%; }

@media (min-width: 589px) {
  .tpl-director .content .related .related--item.commercial {
    margin-left: 0%;
    width: 49.5%;
    float: left;
    margin-right: 1.0%;
    margin-bottom: 1%; }
    .tpl-director .content .related .related--item.commercial:nth-child(2n) {
      width: 49.5%;
      float: right;
      margin-right: 0; } }

@media (min-width: 1023px) {
  .tpl-director .content .related .related--item.commercial {
    width: 32.66667%;
    float: left;
    margin-right: 1.0%;
    margin-bottom: 1%; }
    .tpl-director .content .related .related--item.commercial:nth-child(2n) {
      width: 32.66667%;
      float: left;
      margin-right: 1.0%; }
    .tpl-director .content .related .related--item.commercial:nth-child(3n) {
      width: 32.66667%;
      float: right;
      margin-right: 0; } }

/* Director */
.tpl-director .content .article:after {
  clear: both;
  content: " ";
  display: block;
  line-height: 0; }

.tpl-director .content .article .portrait {
  margin-bottom: 2em; }
  .tpl-director .content .article .portrait:after {
    clear: both;
    content: " ";
    display: block;
    line-height: 0; }
  @media (min-width: 589px) {
    .tpl-director .content .article .portrait {
      margin-bottom: 0; } }
  .tpl-director .content .article .portrait img {
    display: block;
    height: auto;
    width: 100%; }

.tpl-director .content .article .bio {
  margin-bottom: 2em;
  text-align: justify; }
  .tpl-director .content .article .bio:after {
    clear: both;
    content: " ";
    display: block;
    line-height: 0; }
  @media (min-width: 589px) {
    .tpl-director .content .article .bio {
      margin-bottom: 0; } }
  .tpl-director .content .article .bio h3 {
    font-weight: 700; }

.tpl-director .content .related {
  clear: both;
  margin-top: 12px; }
  .tpl-director .content .related:after {
    clear: both;
    content: " ";
    display: block;
    line-height: 0; }
  .tpl-director .content .related .related--item .image {
    line-height: 0; }
    .tpl-director .content .related .related--item .image img {
      display: block;
      height: auto;
      width: 100%; }

/* Related Projects: Mobile, Tablet (Text Below Images, No Hover for Touch) */
.tpl-director .content .related .related--item {
  margin-bottom: 2em; }
  .tpl-director .content .related .related--item .text {
    color: #0f0e0f;
    font-size: 14px;
    letter-spacing: .75px;
    line-height: 1.35;
    margin-top: .35em;
    text-transform: uppercase; }
    .tpl-director .content .related .related--item .text .name {
      display: block;
      font-size: 14px;
      font-weight: bold; }
    .tpl-director .content .related .related--item .text .divider {
      border-right: 1px solid #0f0e0f;
      font-size: 11px;
      line-height: 1;
      margin-right: 7px;
      padding-right: 7px; }
    .tpl-director .content .related .related--item .text .colon {
      display: none; }
    .tpl-director .content .related .related--item .text .client,
    .tpl-director .content .related .related--item .text .colon {
      font-size: 14px;
      font-weight: bold; }
    .tpl-director .content .related .related--item .text p {
      margin: 0; }

/* Related Projects: Desktop (Text Overlays On Hover) */
@media (min-width: 1023px) {
  .tpl-director .content .related .related--item {
    margin-bottom: 0;
    position: relative; }
    .tpl-director .content .related .related--item .text {
      align-items: center;
      bottom: 0;
      color: transparent;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      justify-content: center;
      left: 0;
      letter-spacing: 1.5px;
      margin-top: 0;
      padding: 24px;
      position: absolute;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      top: 0;
      transition: background ease .6s, color ease .6s; }
      .tpl-director .content .related .related--item .text .client {
        border-right: 1px solid rgba(255, 255, 255, 0);
        font-size: 14px;
        font-weight: bold;
        margin-right: 10px;
        padding-right: 10px;
        transition: border-color ease .6s; }
      .tpl-director .content .related .related--item .text .name a {
        color: transparent;
        transition: color ease .6s; }
      .tpl-director .content .related .related--item .text .colon {
        display: none; }
      .tpl-director .content .related .related--item .text .divider {
        display: none; }
      .tpl-director .content .related .related--item .text:hover {
        background-color: rgba(15, 14, 15, 0.65);
        border: 0;
        color: white;
        text-decoration: none; }
        .tpl-director .content .related .related--item .text:hover .client {
          border-right: 1px solid rgba(255, 255, 255, 0.8); }
        .tpl-director .content .related .related--item .text:hover .name a {
          color: white; } }

/*********************************************************************
 * Error
 *
 */
/* Grid */
.tpl-error .main {
  margin-left: 8.41667%;
  width: 85.59201%;
  float: left;
  margin-right: 0.85592%; }

/* Error */
.tpl-error .main {
  min-height: 500px; }

/*********************************************************************
 * Home
 *
 */
/* Grid */
.tpl-home .container .content .content--header {
  display: none; }

.tpl-home .main-entrance-links {
  padding-bottom: 2rem;
  padding-top: 2rem;
  text-align: center; }
  .tpl-home .main-entrance-links .midnav.primary {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
    text-transform: uppercase; }
    .tpl-home .main-entrance-links .midnav.primary .menu--item {
      color: #0f0e0f;
      display: inline-block;
      line-height: 1;
      list-style: none;
      margin: 0;
      padding: .15em 0 0;
      border-right: 1px solid #555;
      margin-right: .75em;
      padding-right: .75em; }
      .tpl-home .main-entrance-links .midnav.primary .menu--item .menu--link {
        border-bottom: 1px solid transparent;
        display: inline-block;
        line-height: 1;
        text-decoration: none;
        transition: border-bottom-color ease .3s; }
        .tpl-home .main-entrance-links .midnav.primary .menu--item .menu--link:hover {
          border-bottom: 1px solid #0f0e0f; }
      .tpl-home .main-entrance-links .midnav.primary .menu--item:last-child {
        border-right: 0;
        margin-right: 0;
        padding-right: 0; }
  @media (min-width: 940px) {
    .tpl-home .main-entrance-links {
      display: none; } }

/*********************************************************************
 * Jobs
 *
 */
/* Grid */
.tpl-jobs .container .main .content {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0; }
  @media (min-width: 767px) {
    .tpl-jobs .container .main .content .article {
      margin-left: 16.83333%;
      width: 66.33333%;
      float: left;
      margin-right: 1.0%; } }

/* Jobs */
.tpl-jobs .content h3 {
  line-height: 1.8; }

.tpl-jobs .content .article h2:first-child {
  margin-top: 0; }

/*********************************************************************
 *  Maintenance
 *
 */
.tpl-maintenance h1 {
  color: #C00;
  font-weight: 400;
  margin-top: 2em;
  text-align: center;
  text-transform: uppercase; }

.tpl-maintenance p.text {
  color: #C00;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  text-align: center;
  text-transform: uppercase; }

.tpl-maintenance .header-menu li {
  border-color: #ccc !important;
  color: #ccc; }
  .tpl-maintenance .header-menu li svg {
    fill: #ccc !important; }

.tpl-maintenance .mobile-menu li {
  border-color: #555 !important;
  color: #555; }
  .tpl-maintenance .mobile-menu li svg {
    fill: #555 !important; }

.tpl-maintenance footer li {
  border-color: #ccc !important;
  color: #ccc; }

/*********************************************************************
 * Project
 *
 */
/* Grid */
.tpl-project {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0; }
  .tpl-project .description {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    @media (min-width: 480px) {
      .tpl-project .description {
        width: 91.58333%;
        float: left;
        margin-right: 1.0%; } }
    @media (min-width: 767px) {
      .tpl-project .description {
        width: 74.75%;
        float: left;
        margin-right: 1.0%; } }
    @media (min-width: 1023px) {
      .tpl-project .description {
        width: 57.91667%;
        float: left;
        margin-right: 1.0%; } }
  .tpl-project .credits .daten {
    vertical-align: bottom; }
  @media (min-width: 639px) {
    .tpl-project .credits .label {
      white-space: nowrap; } }

/* Project General */
.tpl-project {
  background: #0f0e0f;
  color: white;
  font-weight: 300;
  line-height: 2; }
  .tpl-project h1 .client,
  .tpl-project h1 .divider {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 2.5px;
    text-transform: uppercase; }
  .tpl-project h1 .colon {
    display: none; }
  .tpl-project h1 .divider {
    border-right: 1px solid white;
    font-size: 16px;
    line-height: 1;
    margin-right: 10px;
    padding-right: 9px; }
  .tpl-project h1 .title {
    font-size: 17.5px;
    font-weight: 300;
    letter-spacing: 2.5px;
    text-transform: uppercase; }
  .tpl-project h1 .btn.btn-vsm {
    margin-left: 24px; }
  .tpl-project .video {
    position: relative; }
    .tpl-project .video .sidenav {
      position: absolute;
      top: calc(50% - 25px);
      z-index: 5; }
      .tpl-project .video .sidenav.prev {
        left: -40px; }
      .tpl-project .video .sidenav.next {
        right: -40px; }
      .tpl-project .video .sidenav a {
        display: block; }
        .tpl-project .video .sidenav a svg {
          height: 50px;
          width: 50px; }
          .tpl-project .video .sidenav a svg #next,
          .tpl-project .video .sidenav a svg #prev {
            fill: white; }
  .tpl-project .credits {
    letter-spacing: 1.5px;
    text-transform: uppercase; }
    .tpl-project .credits td a {
      color: white; }
    .tpl-project .credits .label {
      font-weight: bold;
      padding-right: 20px; }
  .tpl-project .description {
    margin-top: 1.75em; }

/* Video Player */
.tpl-project.tpl-commercial .video,
.tpl-project.tpl-fiction .video {
  height: 0;
  padding-bottom: 56.25%;
  padding-top: 0;
  position: relative; }
  .tpl-project.tpl-commercial .video iframe,
  .tpl-project.tpl-fiction .video iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

/* Image Slider */
.tpl-project.tpl-fiction .slick-slider.no-js .slick-slide {
  display: none; }
  .tpl-project.tpl-fiction .slick-slider.no-js .slick-slide:first-child {
    display: block;
    float: none;
    margin-bottom: 30px; }

/*********************************************************************
 * Projects
 *
 */
/* Grid */
/* Grid Commercial */
@media (min-width: 589px) {
  .tpl-projects.tpl-commercial-list .project {
    width: 49.5%;
    float: left;
    margin-right: 1.0%; }
    .tpl-projects.tpl-commercial-list .project:nth-child(2n) {
      width: 49.5%;
      float: right;
      margin-right: 0; } }

@media (min-width: 1023px) {
  .tpl-projects.tpl-commercial-list .project {
    width: 32.66667%;
    float: left;
    margin-right: 1.0%; }
    .tpl-projects.tpl-commercial-list .project:nth-child(2n) {
      width: 32.66667%;
      float: left;
      margin-right: 1.0%; }
    .tpl-projects.tpl-commercial-list .project:nth-child(3n) {
      width: 32.66667%;
      float: right;
      margin-right: 0; } }

/* Grid Fiction */
.tpl-projects.tpl-fiction-list .project {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0; }
  @media (min-width: 453px) {
    .tpl-projects.tpl-fiction-list .project {
      width: 49.5%;
      float: left;
      margin-right: 1.0%; }
      .tpl-projects.tpl-fiction-list .project:nth-child(2n) {
        width: 49.5%;
        float: right;
        margin-right: 0; } }
  @media (min-width: 910px) {
    .tpl-projects.tpl-fiction-list .project {
      width: 32.66667%;
      float: left;
      margin-right: 1.0%; }
      .tpl-projects.tpl-fiction-list .project:nth-child(2n) {
        width: 32.66667%;
        float: left;
        margin-right: 1.0%; }
      .tpl-projects.tpl-fiction-list .project:nth-child(3n) {
        width: 32.66667%;
        float: right;
        margin-right: 0; } }

/* Projects */
.tpl-projects .project .image {
  margin-bottom: 3%;
  position: relative; }
  .tpl-projects .project .image img {
    border: 1px solid #f0f0f0;
    display: inline-block;
    height: auto;
    vertical-align: bottom;
    width: 100%; }
  .tpl-projects .project .image .image--edit-overlay {
    position: absolute;
    right: 10px;
    top: 0; }
  .tpl-projects .project .image .image--text-overlay p {
    margin: 0; }
  .tpl-projects .project .image .image--text-overlay .btn {
    display: block; }

/* Projects: Mobile, Tablet (Text Below Images, No Hover for Touch) */
.tpl-projects .content .project .image .image--text-overlay {
  color: #0f0e0f;
  font-size: 14px;
  letter-spacing: .75px;
  line-height: 1.35;
  margin-top: .35em;
  text-transform: uppercase; }
  .tpl-projects .content .project .image .image--text-overlay .name {
    display: block;
    font-size: 14px;
    font-weight: bold; }
  .tpl-projects .content .project .image .image--text-overlay .divider {
    border-right: 1px solid #0f0e0f;
    font-size: 11px;
    line-height: 1;
    margin-right: 7px;
    padding-right: 7px; }
  .tpl-projects .content .project .image .image--text-overlay .colon {
    display: none; }
  .tpl-projects .content .project .image .image--text-overlay .client,
  .tpl-projects .content .project .image .image--text-overlay .colon {
    font-size: 14px;
    font-weight: bold; }

.tpl-projects .content .project .image .image--text-overlay.default {
  align-items: center;
  bottom: 0;
  color: #0f0e0f;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  left: 0;
  letter-spacing: 1.5px;
  margin-top: 0;
  padding: 24px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition: background ease .6s, color ease .6s;
  color: #0f0e0f; }

/* Projects: Desktop (Text Overlays On Hover) */
@media (min-width: 1023px) {
  .tpl-projects .content .project .image .image--text-overlay {
    align-items: center;
    bottom: 0;
    color: transparent;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    justify-content: center;
    left: 0;
    letter-spacing: 1.5px;
    margin-top: 0;
    padding: 24px;
    position: absolute;
    right: 0;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    transition: background ease .6s, color ease .6s; }
    .tpl-projects .content .project .image .image--text-overlay .client {
      border-right: 1px solid rgba(255, 255, 255, 0);
      font-size: 14px;
      font-weight: bold;
      margin-right: 10px;
      padding-right: 10px;
      transition: border-color ease .6s; }
    .tpl-projects .content .project .image .image--text-overlay .name a {
      color: transparent;
      transition: color ease .6s; }
    .tpl-projects .content .project .image .image--text-overlay .colon {
      display: none; }
    .tpl-projects .content .project .image .image--text-overlay .divider {
      display: none; }
    .tpl-projects .content .project .image .image--text-overlay:hover {
      background-color: rgba(15, 14, 15, 0.65);
      border: 0;
      color: white;
      text-decoration: none; }
      .tpl-projects .content .project .image .image--text-overlay:hover .client {
        border-right: 1px solid rgba(255, 255, 255, 0.8); }
      .tpl-projects .content .project .image .image--text-overlay:hover .name a {
        color: white; } }

/* Projects: Fiction Plus Larger */
.tpl-projects.tpl-fiction-list .content .project .image .image--text-overlay {
  display: none; }
  @media (min-width: 1023px) {
    .tpl-projects.tpl-fiction-list .content .project .image .image--text-overlay {
      display: flex;
      font-size: 50px;
      font-weight: 200; } }

.tpl-projects.tpl-fiction-list .content .project .image .image--text-overlay.default {
  display: flex; }
  @media (min-width: 1023px) {
    .tpl-projects.tpl-fiction-list .content .project .image .image--text-overlay.default {
      display: flex;
      font-size: 14px;
      font-weight: 400; } }

/*********************************************************************
 * Team
 *
 */
/* Grid */
.tpl-team .container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto; }
  .tpl-team .container:after {
    content: " ";
    display: block;
    clear: both; }
  .tpl-team .container .content .article {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    @media (min-width: 479px) {
      .tpl-team .container .content .article {
        width: 49.5%;
        float: left;
        margin-right: 1.0%; }
        .tpl-team .container .content .article:nth-child(2n+2) {
          width: 49.5%;
          float: right;
          margin-right: 0; } }
    @media (min-width: 767px) {
      .tpl-team .container .content .article {
        width: 32.66667%;
        float: left;
        margin-right: 1.0%; }
        .tpl-team .container .content .article:nth-child(2n+2) {
          width: 32.66667%;
          float: left;
          margin-right: 1.0%; }
        .tpl-team .container .content .article:nth-child(3n+3) {
          width: 32.66667%;
          float: right;
          margin-right: 0; } }

/* Team: Mobile/Tablet (Text Below Images, No Hover for Touch) */
.tpl-team .article .text {
  color: #0f0e0f;
  font-size: 14px;
  letter-spacing: .75px;
  line-height: 1.35;
  margin-top: .35em;
  text-transform: uppercase;
  min-height: 4em; }
  .tpl-team .article .text .name {
    display: block;
    font-size: 14px;
    font-weight: bold; }
    .tpl-team .article .text .name a {
      text-decoration: underline; }
  .tpl-team .article .text .divider {
    border-right: 1px solid #0f0e0f;
    display: none;
    font-size: 11px;
    line-height: 1;
    margin-right: 7px;
    padding-right: 7px; }
  .tpl-team .article .text .client,
  .tpl-team .article .text .colon {
    font-size: 14px;
    font-weight: bold; }

.tpl-team .article .image {
  line-height: 0; }
  .tpl-team .article .image img {
    border: 1px solid #f0f0f0;
    height: auto;
    width: 100%; }

/* Team: Desktop (Text Overlays On Hover) */
@media (min-width: 1023px) {
  .tpl-team .article {
    margin-bottom: 1%;
    position: relative; }
    .tpl-team .article .text {
      align-items: center;
      bottom: 0;
      color: transparent;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      justify-content: center;
      left: 0;
      letter-spacing: 1.5px;
      margin-top: 0;
      padding: 24px;
      position: absolute;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      top: 0;
      transition: background ease .6s, color ease .6s; }
      .tpl-team .article .text .client {
        border-right: 1px solid rgba(255, 255, 255, 0);
        font-size: 14px;
        font-weight: bold;
        margin-right: 10px;
        padding-right: 10px;
        transition: border-color ease .6s; }
      .tpl-team .article .text .name a {
        color: transparent;
        transition: color ease .6s; }
      .tpl-team .article .text .colon {
        display: none; }
      .tpl-team .article .text .divider {
        display: none; }
      .tpl-team .article .text:hover {
        background-color: rgba(15, 14, 15, 0.65);
        border: 0;
        color: white;
        text-decoration: none; }
        .tpl-team .article .text:hover .client {
          border-right: 1px solid rgba(255, 255, 255, 0.8); }
        .tpl-team .article .text:hover .name a {
          color: white; } }

/*********************************************************************
 * Tips
 *
 */
/* Grid */
.tpl-tips .container .main .content {
  margin-left: 8.41667%;
  width: 83.16667%;
  float: left;
  margin-right: 1.0%; }

/* Tips */
.tpl-tips {
  line-height: 2; }
  .tpl-tips .content--header {
    min-height: 1em; }
  .tpl-tips h1 {
    font-size: 24px;
    font-weight: 200;
    text-transform: uppercase; }
  .tpl-tips h2 {
    border-top: 1px solid #d33;
    color: #d33;
    font-size: 20px;
    font-weight: 200;
    text-transform: uppercase; }

/*********************************************************************
 * Entertainment
 *
 */
/* Grid */
.tpl-entertainment .content p {
  font-size: 20px;
  line-height: 1.3;
  padding: 0 30px;
  margin: 2em auto 0;
  max-width: 950px;
  text-align: center; }
