/*********************************************************************
 *  Maintenance
 *
 */


.tpl-maintenance {

  h1 {
    color: #C00;
    font-weight: 400;
    margin-top: 2em;
    text-align: center;
    text-transform: uppercase;
  }

  p.text {
    color: #C00;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    text-align: center;
    text-transform: uppercase;
  }

  .header-menu li {
    border-color: #ccc!important;
    color: #ccc;
    svg {
      fill: #ccc!important;
    }
  }

  .mobile-menu li {
    border-color: #555!important;
    color: #555;
    svg {
      fill: #555!important;
    }
  }

  footer li {
    border-color: #ccc!important;
    color: #ccc;
  }
} // END: .tpl-maintenance
