/*********************************************************************
 * Entertainment
 *
 */


/* Grid */

.tpl-entertainment {

  .content {

    p {
      font-size: 20px;
      line-height: 1.3;
      // background-color: rgba(255,0,0,.25) !important;
      padding: 0 30px;
      margin: 2em auto 0;
      max-width: 950px;
      text-align: center;
    }
  }



} // END: .tpl-entertainment

