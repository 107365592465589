/*********************************************************************
 * Area Header: Navigation Desktop
 *
 */


/* Header Menu Adjustments for Mobile (Main Menu not visible) */

.header-menu {
  height: 90px;
  margin: 0;
  padding-top: 31px;
}

// On ALL PAGES EXCEPT HOME we show NEUESUPER
.header-menu--content {
  .header-menu--center {
    display: none;
    // margin-left: 93px; // Centering between logo and hamburger
  }

  .header-menu--left {
    display: flex;
    flex-shrink: 0;
    // flex-basis: auto;
  }
}


// On HOME we show COMMERCIAL/FICTION
.tpl-home {
  .header-menu--content {
    .header-menu--center {
      // display: flex;
      margin-left: 0;
      margin-right: 0;
      display: none;
    }

    .header-menu--left {
      display: none;
    }
  }
}

.header-menu--content {
  // display: none;
  display: flex;
  justify-content: space-between;

  .header-menu--center {
    display: none;
    flex: 1;
    // margin-left: 1em;
    // margin-right: 1em;
    margin-left: 0;
    margin-right: 0;
  }
  // .header-menu--left,
  .header-menu--right,
  .topnav.instagram,
  .topnav.facebook {
    display: none;
  }

  .topnav {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    text-transform: uppercase;

    li {
      align-self: center;
      display: inline-block;
      line-height: 1;
      list-style: none;
      margin: 0;
      padding: .15em 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
  } // END: .topnav

  .topnav.primary {
    margin-left: auto;
    margin-right: auto;

    li {
      border-right: 1px solid $list-item-border;
      margin-right: .75em;
      padding-right: .75em;
    }

    li:last-child {
      border-right: 0;
      margin-right: 0;
      padding-right: 0;
    }
  } // END: .topnav.primary

  .topnav.branding {
    li {
      line-height: 0;
      margin: 0;
      padding: 0;
      width: 78px;
    }
  } // END: .topnav.branding

  .topnav.languages {
    display: none;
  } // END: .topnav.languages

}

@media (min-width: 640px) { // Where Commercial/Fiction appears, was 479px
  .header-menu--content {
    .header-menu--center {
      // display: flex;
      margin-right: 93px; // Centering between logo and hamburger
    }
  }

  .tpl-home {
    .header-menu--content {
      .header-menu--center {
        // display: flex;
        margin-right: 1em; // Centering between logo and hamburger
      }
    }
  }
}


@media (min-width: $menu-breakpoint) { // Where the Menu switches


  .mobile-menu,
  .mobile-menu--underlay {
    display: none !important; // Overrides jQuery
  }


  .header-menu {

    height: 117px;
    padding-top: 24px;

    .header-menu--inner {
      .header-menu--content {


        .header-menu--left,
        .header-menu--center,
        .header-menu--right {
          display: flex;
          // flex: 1;
        }

        .header-menu--center {
          margin-right: 0; // Remove Centering from @media-479
        }

        .topnav.languages {
          margin-left: 2em;

          li {
            border-right: 1px solid $list-item-border;
            margin-right: .75em;
            padding-right: .75em;
            &:last-child {
              border-right: 0 solid $list-item-border;
              margin-right: 0;
              padding-right: 0;
            }
          }
        } // END: .topnav.languages



        .topnav.secondary {
          margin-left: auto;
          margin-right: 0;

          li {
            border-right: 1px solid $list-item-border;
            margin-right: .6em;
            padding-right: .6em;

            &:last-child {
              border-right: 0;
              margin-right: 0;
              padding-right: 0;
            }

          }
        } // END: .topnav.secondary

        .topnav.facebook {
          display: flex;
          margin-left: 1.0em;

          li {
            height: 18px;
            width: 18px;

            svg {
              fill: $black;
              height: auto;
              transition: fill ease .3s;
              width: 100%;

              &:hover {
                fill: $fb-blue;
              }
            }
          }
        } // END: .topnav.facebook

        .topnav.instagram {
          display: flex;
          margin-left: 1.0em;

          li {
            height: 18px;
            width: 18px;

            svg {
              fill: $black;
              height: auto;
              transition: fill ease .3s;
              width: 100%;

              &:hover {
                fill: $black-65;
              }
            }
          }
        } // END: .topnav.instagram

        .topnav.languages {
          display: block;
        } // END: .topnav.languages

      } // END:  .header-menu--content
    } // END:  .header-menu--inner
  } // END:  .header-menu


} // END:  media query




/* Basic Header Nav Settings */

.header-menu {
  .header-menu--content {
    .header-menu--left,
    .header-menu--center,
    .header-menu--right {

      // Link Text Styles

      .menu--item { // <a>
        border-bottom: 1px solid transparent;
        color: $black;
        display: inline-block;
        line-height: 1;
        text-decoration: none;
        transition: border-bottom-color ease .3s;

        &:hover {
          border-bottom: 1px solid $black;
        }

        &.current {
          border-bottom: 1px solid transparent;
          cursor: default;
          font-weight: bold;
          pointer-events: none;
        }
      } // END: .menu--item
    }
  }
}
