/*********************************************************************
 * Error
 *
 */

/* Grid */

.tpl-error {

  .main {
    @include pre(1);
    @include span(12 of 14);
  }

}


/* Error */

.tpl-error {
  .main {
    min-height: 500px;
  }
}
