/*********************************************************************
 * Back To Top Button
 *
 */


/* Back to top button */

.back2top {
  // bottom: -70px;
  bottom: 10px;
  display: none;
  left: 0;
  padding-left: $body-padding;
  padding-right: $body-padding;
  pointer-events: none;
  position: fixed;
  right: 0;
  text-align: right;
  width: 100%;

  @include susy-breakpoint(480px, 12) {
    bottom: 60px;
  }

  .back2top--inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    width: 100%;

    .back2top--button {
      cursor: pointer;
      display: inline-block;
      height: 70px;
      margin-right: 2.1875%;
      pointer-events: auto;
      position: relative;
      vertical-align: bottom;
      width: 70px;

      svg {
        height: 70px;
        width: 70px;

        #circle {
          fill: $black;
          transition: fill ease .3s;
        }

        #arrow {
          fill: $white;
          transition: fill ease .3s;
        }

        #bg {
          fill: none;
        }

        &:hover {

          #up {
            fill: $highlight;
          }
        }
      }
    }
  }
}
