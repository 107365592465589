/*********************************************************************
 * Main content and sidebars
 *
 */

.align_left {
  /* for images placed in rich text editor */
  float: left;
  margin: 0 1em 0.5em 0;
  position: relative;
  top: 0.5em;
  max-width: 50%;
}

.align_right {
  /* for images placed in rich text editor */
  float: right;
  margin: 0 0 0.5em 1em;
  max-width: 50%;
}

.align_center {
  /* for images placed in rich text editor */
  display: block;
  margin: 1em auto;
  position: relative;
  top: .5em;
}

figure {
  display: table;
  margin: 1em 0;
  width: 1px;
}

figure img {
  display: table-row;
  margin-bottom: .5em;
}

figure figcaption {
  color: #777;
  display: table-row;
  font-size: smaller;
  line-height: 1.4em;
}
