/*********************************************************************
 * Projects
 *
 */



/* Grid */


/* Grid Commercial */

.tpl-projects.tpl-commercial-list {
  .project {

    @include susy-breakpoint(589px, 12) { // 626px is our max image width!
      @include span(6 of 12);
      &:nth-child(2n) { @include span(6 of 12 last); }
    }

    @include susy-breakpoint(1023px, 12) {
      @include span(4 of 12);
      &:nth-child(2n) { @include span(4 of 12); }
      &:nth-child(3n) { @include span(4 of 12 last); }
    }
  }
}


/* Grid Fiction */

.tpl-projects.tpl-fiction-list {
  .project {

    @include span(12 of 12);

    @include susy-breakpoint(453px, 12) { // 453px is where our image width 406px will not be stretched
      @include span(6 of 12);
      &:nth-child(2n) { @include span(6 of 12 last); }
    }

    @include susy-breakpoint(910px, 12) { // 910px is where our image width 406px will not be stretched
      @include span(4 of 12);
      &:nth-child(2n) { @include span(4 of 12); } // force reset for higher specification rank
      &:nth-child(3n) { @include span(4 of 12 last); }
    }
  }
}


/* Projects */

.tpl-projects {
  .submenu {
    // moved to _area_content--header.scss
  }

  .project {
    .image {
      margin-bottom: 3%;
      position: relative;

      img { // Not all images are linked, so <img> needs to be outside of <a>
        border: 1px solid $light-image-border;
        display: inline-block;
        height: auto;
        vertical-align: bottom;
        width: 100%;
      }

      .image--edit-overlay {
        position: absolute;
        right: 10px;
        top: 0;
      }

      .image--text-overlay {

        p {
          margin: 0;
        }

        .btn {
          display: block;
        }
      }
    }
  }
}

/* Projects: Mobile, Tablet (Text Below Images, No Hover for Touch) */

.tpl-projects {
  .content {
    .project {
      .image {
        .image--text-overlay {
          @include text-under-image();
        }

        .image--text-overlay.default {
          @include text-overlay-default();
          color: $black;
        }
      }
    }
  }
}  // END: .tpl-projects

/* Projects: Desktop (Text Overlays On Hover) */

.tpl-projects {
  .content {
    @include susy-breakpoint(1023px, 12) {
      .project {
        .image {
          .image--text-overlay {
            @include text-overlay();
          }
        }
      }
    } // END: susy-breakpoint

  }
}  // END: .tpl-projects


/* Projects: Fiction Plus Larger */

.tpl-projects.tpl-fiction-list {
  .content {
    .project {
      .image {
        .image--text-overlay {
          display: none;

          @include susy-breakpoint(1023px, 12) {
            display: flex;
            font-size: 50px;
            font-weight: 200;
          }
        }

        .image--text-overlay.default {
          display: flex;

          @include susy-breakpoint(1023px, 12) {
            display: flex;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }

  }
}  // END: .tpl-projects

