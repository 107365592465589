/*********************************************************************
 * Slider for Büro and Projekt
 *
 */

.slicklist {
  background-color: rgba(0, 0, 0, 0.05);
}

.slick-prev, .slick-next {
  // color: rgba(255, 0, 0, 0.35);
  color: red;
}

.cursor-next {
  // cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAgCAYAAADwvkPPAAAABGdBTUEAALGPC/xhBQAAAJhJREFUSA1jYICC////swIxF4xPNg01aCqQXgbE3BQb9OzZszs3bty4R4mBTEBXsAKx4KdPn5giIyMlgAZaAPmzyXYhSCPIRdevX79naGj4DURT4kKGUQNHw5CMvDKabFCSDSijDwygWkSMGkQ4AkfDaIiGERcw5qhTZwINAjVYplKjeQAOTJiBIBcCMfkNF1jUQA2kqEkFAGpQYQTwX2PPAAAAAElFTkSuQmCC"), auto;
}

.cursor-prev {
  // cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAgCAYAAADwvkPPAAAABGdBTUEAALGPC/xhBQAAAKZJREFUSA1jYKAA/P//nx2EKTACohVq0DwgDcLkGwgz6P79+3dBGGYgE6lOhLpi+oMHD+yDg4MlP3z4ADJDEIhJcx2yi4yMjL6dO3fuPlBsPRDzk+SoUYMIB9doGI3oMAJ6nuSMTjDAqJamYDaNGkh+ITgahvNA1RpFFcnQDcPz58/fB+YeUN3JB/MFSTRy1kNuHpBkCLJimIFAmrKGC8xQqIHwNgYAH2ZhG7AR/68AAAAASUVORK5CYII="), auto;
}

/* Adjusting the slick slider dots */
.slick-dots {
  li {
    height: 14px;
    width: 10px;
    button {
      &:before {
        font-size: 10px;
      }
    }
  }
}

.aa-slide {
  height: 100%;
  margin: 0;
  outline: 0 none transparent;
  img {
    width: auto;
  }
  .text-box {
    box-sizing: border-box;
    width: 420px;
    height: 100%;
    padding: 0 20px 10px 60px;
    overflow-y: auto;
//    & p:first-child { margin-top: 0; }
    h1,h2,h3,h4,h5,h6 {
      padding-top: 0;
      margin-top: 0;
      font-size: 24px; // Mozilla Default: 2em (32px)
      line-height: 1.2; // 38px
      font-weight: 300;
      color: black;
      border: 0;
    }
  }
}

.slick-prev:before,
.slick-next:before {
  // color: black;
  color: $white; // Color Arrows
}



// /* Special Case: Slider when JS is disabled */

// .no-js.tpl-buero {
//   td.imageslider {
//     height: 64.5vh;
//   }
// }

// .no-js {
//   td.imageslider {
//     height: 70vh;
//     .slider {
//       height: 100%;
//       white-space: nowrap;
//       overflow: scroll;
//       margin-bottom: 20px;
//     }
//     .aa-slide {
//       height: 100%;
//       vertical-align: top;
//       max-width: none;
//       display:inline-block ;
//       white-space: normal;
//       margin-right: -17px;
//       padding: 0;
//       .text-box {
//         font-size: 16px;
//         height: 100%;
//         padding: 0 37px 10px 60px;
//       }
//       img {
//         margin: 0;
//         outline: 0 none transparent;
//         height: 100%;
//         display: block ;
//         max-width: none;
//       }
//     }
//   }
// }
