/*********************************************************************
 * Director
 *
 */



/* Grid Portrait & Bio */

.tpl-director {
  .content {
    .article {

      @include susy-breakpoint(589px, 12) {
        .portrait { @include span(6 of 12); }
        .bio      { @include span(6 of 12 last); }
      }

      @include susy-breakpoint(767px, 12) {
        // same as 479
      }

      @include susy-breakpoint(1023px, 12) {
        .portrait { @include span(8 of 12);}
        .bio      { @include span(4 of 12 last); @include pre(0); }
      }
    }
  }
}


/* Grid Related */

.tpl-director {
  .content {

    .related {
      .related--item.commercial {
        @include pre(1);
        @include span(10 of 12);
      }
    }

      //   @include susy-breakpoint(479px, 12) {
      //     @include pre(4 of 12);
      //     @include span(6 of 12);
      //     &:nth-child(even) {
      //       @include pre(2 of 12);
      //     }
      //   }

      //   @include susy-breakpoint(767px, 12) {
      //     // same as 479
      //   }
      // }

    @include susy-breakpoint(589px, 12) {
      .related {
        .related--item.commercial {
          @include pre(0);
          @include span(6 of 12);
          margin-bottom: 1%;

          &:nth-child(2n) {
            @include span(6 of 12 last);
          }
        }
      }
    }

    @include susy-breakpoint(1023px, 12) {
      .related {
        .related--item.commercial {
          @include span(4 of 12);
          margin-bottom: 1%;

          &:nth-child(2n) {
            @include span(4 of 12);
          }

          &:nth-child(3n) {
            @include span(4 of 12 last);
          }
        }
      }
    }

  }
}






/* Director */

.tpl-director {
  .content {
    .submenu {
      // moved to _area_submenu.scss
    }

    .article {
      @include cf();

      .portrait {
        @include cf();
        margin-bottom: 2em;

        @include susy-breakpoint(589px, 12) {
          margin-bottom: 0;
        }


        img {
          display: block;
          height: auto;
          width: 100%;
        }
      }

      .bio {
        @include cf();
        margin-bottom: 2em;
        text-align: justify;

        @include susy-breakpoint(589px, 12) {
          margin-bottom: 0;
        }

        h3 {
          font-weight: 700;
        }
      }
    }

    .related {
      @include cf();
      clear: both;
      margin-top: 12px;

      .related--item {
        &.commercial {
          // clear: both;
        }

        .image {
          line-height: 0;

          img {
            display: block;
            height: auto;
            width: 100%;
          }
        } // END: .image
      }
    }
  }
}  // END: .tpl-director


/* Related Projects: Mobile, Tablet (Text Below Images, No Hover for Touch) */

.tpl-director {
  .content {
    .related {
      .related--item {
        margin-bottom: 2em;

        .text {
          @include text-under-image();
          p {
            margin: 0;
          }
        }
      }
    }
  }
}  // END: .tpl-director


/* Related Projects: Desktop (Text Overlays On Hover) */

.tpl-director {
  .content {
    @include susy-breakpoint(1023px, 12) {
      .related {
        .related--item {
          margin-bottom: 0;
          position: relative;

          .text {
            @include text-overlay();
          }
        }
      }
    } // END: susy-breakpoint
  }
}  // END: .tpl-director

