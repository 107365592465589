//****************************************************************************
// Mixins & Extends
//
//


// Clearfix
// ===========================================================================
// Usage: @include cf();

@mixin cf() {
  &:after {
    clear: both;
    content: " ";    // *1
    display: block; // *2
    line-height: 0;
  }
}


// Text Display under Image (Mobile/Tablet)
// ===========================================================================
// Usage: @include text-under-image();

@mixin text-under-image() {
  color: $black;
  font-size: 14px; //15px;
  letter-spacing: .75px;
  line-height: 1.35;
  margin-top: .35em;
  text-transform: uppercase;

  .name {
    display: block;
    font-size: 14px; //16px;
    font-weight: bold;
  }

  .divider {
    border-right: 1px solid $black;
    font-size: 11px;
    line-height: 1;
    margin-right: 7px;
    padding-right: 7px;
  }

  .colon {
    display: none; // Colon is just present to have a semantic separation between client and title.
  }

  .client,
  .colon {
    font-size: 14px; //16px;
    font-weight: bold;
  }
}


@mixin text-under-image-team() {
  color: $black;
  font-size: 14px; //15px;
  letter-spacing: .75px;
  line-height: 1.35;
  margin-top: .35em;
  text-transform: uppercase;

  .name {
    display: block;
    font-size: 14px; //16px;
    font-weight: bold;

    a {
      text-decoration: underline;
    }
  }

  .divider {
    border-right: 1px solid $black;
    display: none;
    font-size: 11px;
    line-height: 1;
    margin-right: 7px;
    padding-right: 7px;
  }

  .client,
  .colon {
    font-size: 14px; //16px;
    font-weight: bold;
  }
}


// Text Display on Image Hover (Desktop)
// ===========================================================================
// Usage: @include text-overlay();

@mixin text-overlay() {
  align-items: center;
  bottom: 0;
  color: transparent;
  display: flex;
  flex-direction: column;
  font-size: 14px; //15px;
  justify-content: center;
  left: 0;
  letter-spacing: 1.5px;
  margin-top: 0;
  padding: 24px;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  transition: background ease .6s, color ease .6s;

  .client {
    border-right: 1px solid $white-0;
    font-size: 14px; //16px;
    font-weight: bold;
    margin-right: 10px;
    padding-right: 10px;
    transition: border-color ease .6s;
  }

  .name a { // team
    color: transparent;
    transition: color ease .6s;
  }

  .colon {
    display: none;
  }

  .divider {
    display: none;
  }

  &:hover {
    background-color: $black-65;
    border: 0;
    color: $white;
    text-decoration: none;

    .client {
      border-right: 1px solid $white-80;
    }

    .name a { // team
      color: $white;
    }
  }
}

// Text Display on Default Image Hover (Mobile)
// ===========================================================================
// Usage: @include text-overlay-default();

@mixin text-overlay-default() {
  align-items: center;
  bottom: 0;
  color: $black;
  display: flex;
  flex-direction: column;
  font-size: 14px; //15px;
  justify-content: center;
  left: 0;
  letter-spacing: 1.5px;
  margin-top: 0;
  padding: 24px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition: background ease .6s, color ease .6s;
}


// Disable selectability for text elements
// ===========================================================================
// Usage: @extend %not-selectable;

%not-selectable {
  -webkit-touch-callout: none; // iOS Safari
  -webkit-user-select: none;   // Chrome/Safari/Opera
  -khtml-user-select: none;    // Konqueror
  -moz-user-select: none;      // Firefox
  -ms-user-select: none;       // IE/Edge
  user-select: none;           // non-prefixed version, currently not supported by any browser
}
