/*********************************************************************
 * Area Header: Navigation Project
 *
 */



.tpl-project {
  .header-menu--project {
    .header-menu--inner {
      .header-menu--content {
        .header-menu--admin {
          .btn.admin, .spacer {
            display: none;
          }
        }
      }
    }
  }
}


@media (min-width: 479px) {

  .tpl-project {
    .header-menu--project {
      .header-menu--inner {
        .header-menu--content {
          .header-menu--admin {
            .btn.admin, .spacer {
              display: inline-block;
            }
          }
        }
      }
    }
  }

}



.tpl-project {
  .header-menu--project {
    .header-menu--inner {
      .header-menu--content {
        // background-color: rgba(50, 50, 50, 0.5);

        display: flex;
        justify-content: space-between;
        // justify-content: flex-end;
        height: 75px;
        width: 100%;
        margin-right: 0;

        .topnav {
          display: flex;
          margin: 0;
          text-transform: uppercase;
          list-style: none;
          padding: 0;

          li {
            align-self: center;
            display: inline-block;
            line-height: 1;
            list-style: none;
            margin: 0;
            padding: .15em 0 0;

            &:last-child {
              margin-right: 0;
            }

            .menu--item { // <a>
              border-bottom: 1px solid transparent;
              color: $white;
              display: inline-block;
              line-height: 1;
              text-decoration: none;
              transition: border-bottom-color ease .3s;

              &:hover {
                border-bottom: 1px solid $white;
              }

              &.current {
                font-weight: bold;
                cursor: default;
                border-bottom: 1px solid transparent;
                pointer-events: none;
              }
            } // END: .menu--item
          } // END: li
        } // END: .topnav



        .header-menu--left {
          display: flex;
          flex: 1;

          .topnav.branding {
            li {
              width: 78px;
              svg {
                width: 100%;
                height: auto;
                fill: $white;
              }
            }
          } // END: .topnav.branding

          .topnav.languages {
            margin-left: 2em;
          li {
            border-right: 1px solid $list-item-border-on-dark;
            margin-right: .75em;
            padding-right: .75em;
            &:last-child {
              border-right: 0 solid $list-item-border-on-dark;
              margin-right: 0;
              padding-right: 0;
            }
          }
          } // END: .topnav.languages

        }


        .header-menu--close {
          align-self: center;
          margin-right: 0;
          display: inline-block;

          a.close {
            margin-right: -10px;
            display: inline-block;
            text-align: center;
            vertical-align: top;
            width: 50px;
            height: 50px;
            transition: all ease 0.18s;

            &:hover,
            &:active,
            &:focus {
              border: 0;
            }

            svg {

              #bg-close {
                fill: $bg-black;
                transition: fill ease 0.18s;
              }

              #x-close {
                fill: $white;
                transition: fill ease 0.18s;
              }

              &:hover {

                #bg-close {
                  fill: $black;
                }

                #x-close {
                  fill: $white;
                }
              }
            }
          }
        }

        .header-menu--admin {
          display: flex;
          flex: 0;
          width: auto;
          align-self: center;
          padding-right: 30px;
          // display: inline-block;

          .topnav.admin {
            margin: 0;
            white-space: nowrap;
          }
        }

      } // END:  .header-menu--content
    } // END:  .header-menu--inner
  } // END:  .header-menu

} // END:  .tpl-project




