/*********************************************************************
 * About
 *
 */

/* Grid */

.tpl-about {
  .container {
    .main {
      .content {
        .article {
          @include span(12 of 12);
          .text {
            @include span(12 of 12);

            @include susy-breakpoint(767px, 12) {
              // @include pre (1);
              @include span(6 of 12);
            }
          }
          .image {
            @include span(12 of 12);
          }
        }
      }
    }
  }
}  // END: .tpl-about

/* About */

.tpl-about {
  .container {
    .main {
      .content {
        .article {
          .image {
            img {
              width: 100%;
              height: auto;
              margin-bottom: 2em;
            }
          }
        }
      }
    }
  }
}  // END: .tpl-about
