/*********************************************************************
 * Hamburger
 * Source: https://codepen.io/anon/pen/qmeVRY
 */



.hamburger {
  display: block;
}

@media (min-width: $menu-breakpoint) { // Where the Menu switches

  .hamburger {
    display: none;
  }

}


.hamburger {

  cursor: pointer;
  height: 42px;
  position: absolute;
  right: -10px;
  top: 20px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  width: 50px;
  z-index: 505;

  // Normalize <button>
  background-color: transparent;
  border: 0;
  color: inherit;
  font: inherit;
  margin: 0;
  overflow: visible;
  text-transform: none;


  span {
    background: $black;
    display: block;
    height: 2px;
    left: 10px;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    width: 30px;

    &:nth-child(1) { top: 10px;  }

    &:nth-child(2),
    &:nth-child(3) { top: 20px; }

    &:nth-child(4) { top: 30px; }
  }

  &.is-active {

    span {
      background-color: $white;
      right: 49px;

      &:nth-child(1) {
        left: 23px;
        top: 20px;
        width: 0%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        left: 23px;
        top: 20px;
        width: 0%;
      }

    }
  }

}




