/*********************************************************************
 * Susy Grid
 *
 */

// http://susydocs.oddbird.net

//  1 cols =   81px
//  2 cols =  190px
//  3 cols =  299px
//  4 cols =  408px
//  5 cols =  517px
//  6 cols =  626px
//  7 cols =  735px
//  8 cols =  844px
//  9 cols =  953px
// 10 cols = 1062px
// 11 cols = 1171px
// 12 cols = 1280px


//  body { @include container(80em); }
//  nav { @include span(25%); }

$susy: (
  flow: ltr,
  math: fluid,
  output: float,
  gutter-position: after, // default: after
  container: auto, // default: auto
  container-position: center,
  columns: 12, // default: 4
  // gutters: 28/81,
  gutters: .13483146,
  column-width: false,
  global-box-sizing: content-box,
  last-flow: to,
  debug: (
    image: hide, // default
    // image: show, // for debugging
    color: rgba($red, .05), // default: rgba(#66f, .25);
    output: background,
    toggle: top right,
  ),
  use-custom: (
    background-image: true,
    background-options: false,
    box-sizing: true,
    clearfix: false,
    rem: true,
  ),
  role: nest
);

/* GLOBAL CONTAINER SETTINGS */

  .footer,
  .container {
    @include container(1280px 12 .13483146 after fluid);  // 28/81 or 0.345679012
  }

html,
body {
  padding: 0;
  margin: 0;
}

.body, .body--inner, footer {
  position: relative;
}

$body-padding: 67/1920*100%; // 67px @ 1920px (full width)

// .body, footer {
//   padding-left: $body-padding; // 67px @ 1920px (full width)
//   padding-right: $body-padding;
// }


.body, footer {
  padding-left: 30px;
  padding-right: 30px;


  @include susy-breakpoint(875px, 12) { // @ 860px the padding would get below 30px
    padding-left: $body-padding; /* 67px @ 1920px (full width) */
    padding-right: $body-padding;
  }
}
