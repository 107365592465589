/*********************************************************************
 * Jobs
 *
 */



/* Grid */


.tpl-jobs {
  .container { // = SUSY CONTAINER
    .main {
      .content {
        @include span(12 of 12);

        @include susy-breakpoint(767px, 12) {
          .article {
            @include pre (2);
            @include span(8 of 12);
          }
        }
      }
    }
  }
} // END: .tpl-jobs


/* Jobs */

.tpl-jobs {

  .content {

    h3 {
      line-height: 1.8;
    }
    .article {
      h2:first-child {
        margin-top: 0;
      }

      // @include cf();

    }
  }

} // END: .tpl-jobs

