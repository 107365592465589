//////////////////////////////////////////////////////////////////////////////
// Area: Content Header //////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

// ***************************************************************************
// Grid


.content--header {
  .content--header--inner {
    .submenu {
      @include susy-breakpoint(767px, 12) {
        @include pre(1);
        @include span(10 of 12);
      }
    }
  }
}


// ***************************************************************************
// Content Header

.content--header {
  @include cf();
  margin-bottom: 2em;

  .content--header--inner {

    .submenu {
      font-weight: normal;
      line-height: 1.5;
      list-style: none;
      margin-bottom: 0;
      margin-top: 1em;
      padding: 2% 0 2.5%;
      text-align: center;
      text-transform: uppercase;

      &.directors {
        text-transform: none;
      }

      li {
        display: inline-block;
        height: rem(15);
        line-height: 1.25;
        list-style: none;
        margin-right: .425em;
        margin-left: .425em;
        vertical-align: middle;
        // padding: .4em 0 0;


        &.divider-after {
          @include susy-breakpoint(480px, 12) {
            border-right: 1px solid $list-item-border;
            line-height: 1.25;
            // margin-right: .425em;
            padding: 0 .9em 0 0;
          }
        }

        // &.directors {
        //   &:before {
        //     @include susy-breakpoint(480px, 12) {
        //       border-left: 1px solid $list-item-border;
        //       content: "";
        //       line-height: 1.25;
        //       margin-left: .65em;
        //       padding: 0 0 0 .85em;
        //     }
        //   }
        // }
      }

      .submenu-divider--mobile { // <hr>
        border: 0;
        border-bottom: 1px solid $list-item-border;
        height: 0;
        margin: 1em auto .8em;
        padding: 0;
        width: 20px;

        @include susy-breakpoint(480px, 12) {
          display: none;
        }
      }


      .submenu--item { // <a> or <span>
        border-bottom: 1px solid transparent;
        color: $black;
        display: inline-block;
        text-decoration: none;
        transition: border-bottom-color ease .3s;

        &.current {
          font-weight: bold;
        }

        &:hover {
          border-bottom: 1px solid $black;
        }

        &.no-link:hover {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
}


// ***************************************************************************
// Exceptions

// .tpl-commercial-list {
//   .content--header {
//     .content--header--inner {
//       .submenu {
//         li {
//           &:nth-last-child(3) {
//             margin-right: 0;
//           }
//         }
//       }
//     }
//   }
// }

.tpl-home .content--header,
.tpl-project .content--header {
  display: none;
}
