/*********************************************************************
 * Tips
 *
 */


/* Grid */

.tpl-tips {
  .container { // = SUSY CONTAINER
    .main {
      // @include span(12 of 12);

      .content {
        @include pre(1);
        @include span(10 of 12);
      }
    }
  }
}


/* Tips */

.tpl-tips {

  line-height: 2;

  .content--header {
    min-height: 1em;
  }

  h1 {
    font-size: 24px;
    font-weight: 200;
    text-transform: uppercase;
  }

  h2 {
    border-top: 1px solid $red-highlight;
    color: $red-highlight;
    font-size: 20px;
    font-weight: 200;
    text-transform: uppercase;
  }

}

