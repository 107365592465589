/*********************************************************************
 * 1. General HTML tags
 *
 */



// html {
//   font-size: 14px;
//   line-height: 1.3;
//   h1 { font-size: 28px; line-height: 26px; } // Mozilla Default: 2em (32px)
//   h2 { font-size: 20px; line-height: 1.2; } // Mozilla Default: 2em (32px)
//   // h3 { font-size: px;}
//   // h4 { font-size: px;}
// }

// @include susy-breakpoint(767px,14) {
  html {
    font-size: 16px;
    line-height: 1.8;
    &.noscroll {
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
    }
  }

  h1 { font-size: 38px; line-height: 36px; } // Mozilla Default: 2em (32px)
  h2 { font-size: 24px; line-height: 1.4; } // Mozilla Default: 2em (32px)
  // h3 { font-size: px;}
  // h4 { font-size: px;}
// }

html, body { height: 100%;}

body > footer {
  // position: sticky;
  // top: 100vh;
}

* {
  box-sizing: border-box;
}

div::-moz-focus-inner,
input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0 none transparent;
}
a, img, textarea, input, button { outline: 0 none transparent; }


html {
  overflow-y:scroll;
  background-color: $bg-white;
  color: $black;
  margin:0;
}

body {
  margin:0;
}

body, td, input[type=text], textarea {
  font-family: "Nunito Sans", "Avenir", "Avenir Next", serif; // "Nationale", "Gill Sans"
  // font-family: "Helvetica", "Helvetica Neue", "Arial", sans-serif;
  font-weight: normal;
  // font-size: 106.25%; /* 17px */
  // font-size: 100%;    /* 16px */
  // font-size: 87.5%;   /* 14px */
  font-size: 14px;
  // line-height: 1.5;

}

img {
  max-width: 100%;
}

h1 {
//  text-transform: uppercase;
//  font-weight: normal;
  font-weight: normal;

//  letter-spacing: 0.15em;
}

h2 {
  font-weight: normal;
  color: $black;
}

h3 {
  color: $black;
  font-weight: normal;
  text-transform: uppercase;
  margin-top: 0;
  padding: 0;
  line-height: 1;
}

p {
//  background-color: rgba(255,0,0,0.25);
  // margin: 0.6em 0;
  margin: 1em 0;
  padding: 0;
}

a:link,
a:visited,
a:hover,
a:active,
a:focus {
  outline: 0;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
  outline-width: 0;
}


// a:link,
// a:visited {
//   color: $black;
//   text-decoration: none;
//   border: 0;
// }

// a:hover,
// a:active,
// a:focus,
// .nav a:hover,
// .nav a:active,
// .nav a:focus {
//   color: $black;
//   border-bottom: 1px solid $black;
//   outline: 0;
//   -webkit-transition: all .35s easeInOut;
//      -moz-transition: all .35s easeInOut;
//        -o-transition: all .35s easeInOut;
//           transition: all .35s easeInOut;
// }

a {
  color: $black;
  text-decoration: none;
}

a:focus, a:hover {
  color: $black;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}



/* Links that are not yet implemented */

a.wip {
  &:hover,
  &:active,
  &:focus {
    color: red!important;
    text-decoration: line-through!important;
    border: 0;
  }
}


// /* ProcessWire Editing Links for Administration */
// .edit,
// .topnav .edit {
//   a:link,
//   a:visited {
//     color: #FF0000;
//   }
//   a:hover,
//   a:active,
//   a:focus {
//     color: #FF0000;
//     border-bottom-color: #FF0000;
//     // border-bottom: 1px solid #FF0000;
//   }
// }



blockquote {
  margin-left: 0;
  padding-left: 1.5em;
  padding-right: 2em;
  border-left: 4px solid #ddd;
  font-style: italic;
  color: #777;
}

pre, code {
  background: #eee;
  border: 1px solid #ddd;
}

b, strong {
  font-weight: 700;
}

pre {
  font-size: 14px;
  line-height: 1.4em;
  padding: 1em;
  border-left: 4px solid #ddd;
}


/* Table */

table,
thead,
tbody,
tfoot,
tr,
th,
td {
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    border-collapse: inherit;
    border-spacing: 0;
    border-color: inherit;
    vertical-align: top;
    text-align: left;
    font-weight: inherit;
    -webkit-border-horizontal-spacing: 0;
    -webkit-border-vertical-spacing: 0;
}

.body {
  // min-height: 88vh;
}

